
import programService from "../../services/program.service";
import categoryService from "../../services/category.service";
import venueService from "@/services/venue.service";
import eventDateService from "@/services/event-date.service";
import toastService from "@/services/toast.service";
import { Options, Vue } from "vue-class-component";
import _ from "lodash";
import Constants from "@/core/Constants";

Options({});
export default class EventPrograms extends Vue
{
	async created(): Promise<void>
	{
		await this.fetchPrograms();
	}

	public showProgramDialog = false;
	public dialogDataSaveInProgress = false;
	public dialogData: any = null;

	public additionalDataLoadingInProgress = false;
	public categories: { label: string; value: number }[] = [];
	public venues: { label: string; value: number }[] = [];
	public eventDates: { label: string; value: number }[] = [];

	public dataLoadingInProgress = false;
	public dataLoadingFinished = false;
	public dataFetchError = false;
	public selectedRows = [];
	public programs = null;
	public tHead = ["ID", { value: "HU", class: "header-230" }, "Date", "Start time", "End time", "Location", "Category", "Status", "Registered", { value: "Actions", align: "center" }];
	public tBody = ["id", { field: "programNameHU", class: "cell-230" }, "date", "startTime", "endTime", "locationNameHU", "categoryNameHU","registrationStatus", "totalRegistered", { slot: "actions", align: "center" }];

	public async fetchPrograms(): Promise<void>
	{
		this.dataLoadingInProgress = true;
		this.dataLoadingFinished = false;
		this.dataFetchError = false;
		try
		{
			this.fetchAdditionlData();
			const programs = await programService.getPrograms();
			this.programs = programs.data;
			this.dataLoadingFinished = true;
		}
		catch (error)
		{
			this.dataFetchError = true;
		}
		this.dataLoadingInProgress = false;
	}

	public async fetchAdditionlData(): Promise<void>
	{
		this.additionalDataLoadingInProgress = true;
		try
		{
			this.categories = [];
			this.venues = [];
			this.eventDates = [];
			const categories = await categoryService.getCategories();
			const language = localStorage.getItem(Constants.LANGUAGE_STORAGE_KEY)?.toLowerCase() || "hu";
			categories?.data.forEach((category) => {
				this.categories.push({ label: category[language], value: category.id });
			});
			const venues = await venueService.getVenues();
			venues?.data.forEach((venue) => {
				this.venues.push({ label: venue[language], value: venue.id });
			});
			const eventDates = await eventDateService.getEventDays();
			eventDates?.data.forEach((eventDay) => {
				this.eventDates.push({ label: `${eventDay[language]} - ${eventDay.day}`, value: eventDay.id });
			});
		}
		catch (error)
		{
			//
		}
		this.additionalDataLoadingInProgress = false;
	}

	public editClicked(data): void
	{
		this.openProgramDialog(data);
	}

	public deleteClicked(program): void
	{
		if (!program?.id)
		{
			return;
		}
		this.$confirm({
			title: `${program.hu}`,
			message: `Bisztosan ki akarja torolni az esemenyt?`,
			state: "warning",
			acceptText: "Igen",
			cancelText: "Nem",
		}).then(async (result) => {
			if (result)
			{
				try
				{
					await programService.deleteProgram(program?.id);
					toastService.showToast("Az esemeny torlese sikeres!", "success");
					this.fetchPrograms();
				}
				catch (error)
				{
					toastService.showToast("Az esemeny torlese sikertelen!", "danger");
				}
			}
		});
	}

	public openProgramDialog(program: any = null): void
	{
		this.dialogData = {
			id: program?.id || null,
			hu: program?.programNameHU || null,
			ro: program?.programNameRO || null,
			category: program?.categoryId || null,
			venue: program?.venueId || null,
			date: program?.dateId || null,
			capacity: program?.totalPrioritySpots || 0,
			additionalCapacity: program?.totalWaitingListSpots || 0,
			startingTime: program?.startTime || "12:00:00",
			duration: program?.duration || 60,
			isForChild: program?.isForChild == 0 ? false : true,
			extraTicket: program?.extraTicket || false,
			ticketLink: program?.ticketLink || null,
		};
		this.showProgramDialog = true;
	}

	public async dialogClose(saveClicked: boolean): Promise<void>
	{
		if (saveClicked)
		{
			try
			{
				this.dialogDataSaveInProgress = true;
				this.dialogData.extraTicket = this.dialogData.ticketLink ? true : false;
				await programService.saveProgram(this.dialogData);
				toastService.showToast("Az esemeny mentese sikeres!", "success");
			}
			catch (error)
			{
				toastService.showToast("Az esemeny mentese sikertelen!", "danger");
			}
			this.dialogDataSaveInProgress = false;
			this.showProgramDialog = false;
			this.dialogData = null;
			await this.fetchPrograms();
		}
		else
		{
			this.showProgramDialog = false;
			this.dialogData = null;
		}
	}

	public async cloneEvent(): Promise<void>
	{
		try
		{
			this.dialogDataSaveInProgress = true;
			this.dialogData.extraTicket = this.dialogData.ticketLink ? true : false;
			this.dialogData.id = null;
			await programService.saveProgram(this.dialogData);
			toastService.showToast("Az esemeny mentese sikeres!", "success");
		}
		catch (error)
		{
			toastService.showToast("Az esemeny mentese sikertelen!", "danger");
		}
		this.dialogDataSaveInProgress = false;
		this.showProgramDialog = false;
		this.dialogData = null;
		await this.fetchPrograms();
	}
}
