import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';


class VenueService {
    getVenues() {
        return axios.get(Constants.API_URL + 'api/venues.json', authHeader());
    }

    saveVenue(venue) {
        return axios.post(Constants.API_URL + 'api/venue.json', venue, authHeader());
    }

    deleteVenue(venueId) {
        return axios.delete(Constants.API_URL + `api/venue/${venueId}.json`, authHeader()); 
    }
}

export default new VenueService();