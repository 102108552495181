import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';

class StatisticsService
{
	GetTopRegistrants()
	{
		return axios.get (Constants.API_URL + 'api/statistics/topusers.json', authHeader ());
	}
	GetRegistrations()
	{
		return axios.get (Constants.API_URL + 'api/statistics/registrations.json', authHeader ());
	}
	GetGlobalData()
	{
		return axios.get (Constants.API_URL + 'api/statistics/globaldata.json', authHeader ());
	}
}

export default new StatisticsService ();
