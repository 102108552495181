
import { Options, Vue } from "vue-class-component";
import toastService from "../../services/toast.service";
import eventDateService from "../../services/event-date.service";
import _ from "lodash";

Options({});
export default class EventDays extends Vue {
  //#region Init
  async created(): Promise<void> {
    await this.fetchEventDays();
  }
  //#endregion

  //#region Properties
  public datePickerConfig = {
    defaultDate: "today",
    dateFormat: "Y-m-d",
  };
  public showEventDayDialog = false;
  public dialogDataSaveInProgress = false;
  public dialogData = null;

  public dataLoadingInProgress = false;
  public dataLoadingFinished = false;
  public dataFetchError = false;
  public selectedRows = [];
  public eventDays = null;
  public tHead = ["ID", "Name HU", "Name RO", "Date", { value: "Actions", align: "center" }];
  public tBody = [
    "id",
    "hu",
    "ro",
    "day",
    {
      slot: "actions",
      align: "center"
    },
  ];
  //#endregion

  //#region Helper methods
  public async fetchEventDays(): Promise<void> {
    this.dataLoadingInProgress = true;
    this.dataLoadingFinished = false;
    this.dataFetchError = false;
    this.eventDays = null;
    try {
      const eventDays = await eventDateService.getEventDays();
      this.eventDays = eventDays.data;
      this.dataLoadingFinished = true;
    } catch (error) {
      this.dataFetchError = true;
    }
    this.dataLoadingInProgress = false;
  }
  //#endregion

  //#region List methods
  public editClicked(eventDay): void {
    this.openEventDayDialog(eventDay);
  }

  public deleteClicked(eventDay): void {
    if (!eventDay?.id) {
      return;
    }
    this.$confirm({
      title: `${eventDay.hu}`,
      message: `Bisztosan ki akarja torolni a datumot? Hogyha a datumhoz vanak hozakotve esemenyek akkor az esemenyek is kitorlodnek!`,
      state: "warning",
      acceptText: "Igen",
      cancelText: "Nem",
    }).then(async (result) => {
      if (result) {
        try {
          await eventDateService.deleteEventDay(eventDay?.id);
          toastService.showToast("A datum torlese sikeres!", "success");
          this.fetchEventDays();
        } catch (error) {
          toastService.showToast("A datum torlese sikertelen!", "danger");
        }
      }
    });
  }
  //#endregion

  //#region Dialog methods
  public openEventDayDialog(eventDate = null): void {
    this.dialogData = _.cloneDeep(eventDate) || {
      id: null,
      hu: null,
      ro: null,
      day: null
    };
    this.showEventDayDialog = true;
  }

  public async dialogClose(saveClicked: boolean): Promise<void> {
    if (saveClicked) {
      try {
        this.dialogDataSaveInProgress = true;
        await eventDateService.saveEventDay(this.dialogData);
      } catch (error) {
        toastService.showToast("A datum mentese sikertelen!", "danger");
      }
      this.dialogDataSaveInProgress = false;
      this.showEventDayDialog = false;
      this.dialogData = null;
      await this.fetchEventDays();
    } else {
      this.showEventDayDialog = false;
      this.dialogData = null;
    }
  }
  //#endregion
}
