import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import BalmUI from 'balm-ui';
import BalmUIPlus from 'balm-ui/dist/balm-ui-plus'; 
import 'balm-ui-css';
import { createI18n } from "vue-i18n";
import hu from '@/translations/hu.json';
import ro from '@/translations/ro.json';

const i18n = createI18n({
    locale: "hu",
    messages: {
        hu: hu,
        ro: ro
    }
});

const app = createApp(App); 

app.use(router);
app.use(i18n);
app.use(BalmUI, {
    $theme: {
        // primary: "#fed001",
        // secondary: "#36305e"
    },
    $toast: {
        timeoutMs: 3500 
    }
});
app.use(BalmUIPlus); 
app.mount("#app");
