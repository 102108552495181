<template>
	<div class="admin-list-page">
		<div class="page-header">{{ $t("programs.eventPrograms") }}<span v-if="programs">({{ programs.length }})</span></div>
		<div class="admin-page-content">
			<div class="list-wrapper">
				<div class="data-loader" v-if="dataLoadingInProgress || additionalDataLoadingInProgress">
					<ui-spinner active></ui-spinner>
					<span>{{ $t("programs.loadingData") }}</span>
				</div>
				<ui-table v-if="programs && !dataLoadingInProgress && !additionalDataLoadingInProgress" v-model="selectedRows" fullwidth :data="programs" :thead="tHead" :tbody="tBody" row-checkbox selected-key="id">
					<template #actions="{ data }">
						<ui-icon @click="editClicked(data)">edit</ui-icon>
						<ui-icon @click="deleteClicked(data)">delete</ui-icon>
					</template>
				</ui-table>
			</div>
			<div class="list-footer">
				<ui-button raised :disabled="dataLoadingInProgress || additionalDataLoadingInProgress || dataFetchError" @click="openProgramDialog(null)">
					<ui-icon>add_circle_outline</ui-icon>
					<span>{{ $t("programs.newProgram") }}</span>
				</ui-button>
			</div>
			<!--
			********************************************************************************************************************
			*
			*   PROGRAM DIALOG
			*
			********************************************************************************************************************
			-->
			<ui-dialog v-model="showProgramDialog" @confirm="dialogClose" :closable="false">
				<ui-dialog-title>{{dialogData?.id ? $t("programs.editProgram") : $t("programs.addProgram")}}</ui-dialog-title>
				<ui-dialog-content>
					<ui-form type="1" item-margin-bottom="16" action-align="center" v-if="dialogData">
						<ui-form-field class="text-field required">
							<ui-textfield outlined v-model="dialogData.hu" :attrs="{ name: 'nameHU' }" required>{{ $t("programs.nameHU") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-textfield outlined v-model="dialogData.ro" :attrs="{ name: 'nameRO' }" required>{{ $t("programs.nameRO") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-select type="1" v-model="dialogData.date" :options="eventDates" fullwidth outlined required>{{ $t("eventDate.eventDate") }}</ui-select>
						</ui-form-field>
						<ui-form-field>
							<ui-select type="1" v-model="dialogData.venue" :options="venues" fullwidth outlined required>{{ $t("eventLocation.eventLocation") }}</ui-select>
						</ui-form-field>
						<ui-form-field>
							<ui-select type="1" v-model="dialogData.category" :options="categories" fullwidth outlined required>{{ $t("category.category") }}</ui-select>
						</ui-form-field>
						<ui-form-field>
							<ui-textfield outlined v-model="dialogData.capacity" :attrs="{ name: 'capacity' }" input-type="number" required>{{ $t("programs.capacity") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-textfield outlined v-model="dialogData.additionalCapacity" :attrs="{ name: 'additionalCapacity' }" input-type="number" required>{{ $t("programs.additionalCapacity") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-textfield outlined v-model="dialogData.startingTime" :attrs="{ name: 'startingTime' }" required>{{ $t("programs.startingTime") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-textfield outlined v-model="dialogData.duration" :attrs="{ name: 'duration' }" input-type="number" required>{{ $t("programs.duration") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-textfield outlined v-model="dialogData.ticketLink" :attrs="{ name: 'ticketLink' }">{{ $t("programs.ticketLink") }}</ui-textfield>
						</ui-form-field>
						<ui-form-field>
							<ui-checkbox v-model="dialogData.isForChild" input-id="is-for-children"></ui-checkbox>
							<label for="is-for-children">{{$t("programs.isForChild")}}</label>
						</ui-form-field>
					</ui-form>
				</ui-dialog-content>
				<ui-dialog-actions :acceptText="'Save'">
					<ui-button @click="dialogClose(false)" :disabled="dialogDataSaveInProgress">Mégsem</ui-button>
					<ui-button raised @click="dialogClose(true)" :disabled="dialogDataSaveInProgress">
						<span class="flex-center-10" v-if="dialogDataSaveInProgress">
							<ui-spinner active size="small"></ui-spinner>
							<span>{{ $t("save") }}...</span>
						</span>
						<span v-if="!dialogDataSaveInProgress">{{ $t("save") }}</span>
					</ui-button>
					<ui-button raised @click="cloneEvent()" :disabled="dialogDataSaveInProgress">
						<span class="flex-center-10" v-if="dialogDataSaveInProgress">
							<ui-spinner active size="small"></ui-spinner>
							<span>Mentés újként...</span>
						</span>
						<span v-if="!dialogDataSaveInProgress">Mentés újként</span>
					</ui-button>
				</ui-dialog-actions>
			</ui-dialog>
		</div>
	</div>
</template>

<script lang="ts">
import programService from "../../services/program.service";
import categoryService from "../../services/category.service";
import venueService from "@/services/venue.service";
import eventDateService from "@/services/event-date.service";
import toastService from "@/services/toast.service";
import { Options, Vue } from "vue-class-component";
import _ from "lodash";
import Constants from "@/core/Constants";

Options({});
export default class EventPrograms extends Vue
{
	async created(): Promise<void>
	{
		await this.fetchPrograms();
	}

	public showProgramDialog = false;
	public dialogDataSaveInProgress = false;
	public dialogData: any = null;

	public additionalDataLoadingInProgress = false;
	public categories: { label: string; value: number }[] = [];
	public venues: { label: string; value: number }[] = [];
	public eventDates: { label: string; value: number }[] = [];

	public dataLoadingInProgress = false;
	public dataLoadingFinished = false;
	public dataFetchError = false;
	public selectedRows = [];
	public programs = null;
	public tHead = ["ID", { value: "HU", class: "header-230" }, "Date", "Start time", "End time", "Location", "Category", "Status", "Registered", { value: "Actions", align: "center" }];
	public tBody = ["id", { field: "programNameHU", class: "cell-230" }, "date", "startTime", "endTime", "locationNameHU", "categoryNameHU","registrationStatus", "totalRegistered", { slot: "actions", align: "center" }];

	public async fetchPrograms(): Promise<void>
	{
		this.dataLoadingInProgress = true;
		this.dataLoadingFinished = false;
		this.dataFetchError = false;
		try
		{
			this.fetchAdditionlData();
			const programs = await programService.getPrograms();
			this.programs = programs.data;
			this.dataLoadingFinished = true;
		}
		catch (error)
		{
			this.dataFetchError = true;
		}
		this.dataLoadingInProgress = false;
	}

	public async fetchAdditionlData(): Promise<void>
	{
		this.additionalDataLoadingInProgress = true;
		try
		{
			this.categories = [];
			this.venues = [];
			this.eventDates = [];
			const categories = await categoryService.getCategories();
			const language = localStorage.getItem(Constants.LANGUAGE_STORAGE_KEY)?.toLowerCase() || "hu";
			categories?.data.forEach((category) => {
				this.categories.push({ label: category[language], value: category.id });
			});
			const venues = await venueService.getVenues();
			venues?.data.forEach((venue) => {
				this.venues.push({ label: venue[language], value: venue.id });
			});
			const eventDates = await eventDateService.getEventDays();
			eventDates?.data.forEach((eventDay) => {
				this.eventDates.push({ label: `${eventDay[language]} - ${eventDay.day}`, value: eventDay.id });
			});
		}
		catch (error)
		{
			//
		}
		this.additionalDataLoadingInProgress = false;
	}

	public editClicked(data): void
	{
		this.openProgramDialog(data);
	}

	public deleteClicked(program): void
	{
		if (!program?.id)
		{
			return;
		}
		this.$confirm({
			title: `${program.hu}`,
			message: `Bisztosan ki akarja torolni az esemenyt?`,
			state: "warning",
			acceptText: "Igen",
			cancelText: "Nem",
		}).then(async (result) => {
			if (result)
			{
				try
				{
					await programService.deleteProgram(program?.id);
					toastService.showToast("Az esemeny torlese sikeres!", "success");
					this.fetchPrograms();
				}
				catch (error)
				{
					toastService.showToast("Az esemeny torlese sikertelen!", "danger");
				}
			}
		});
	}

	public openProgramDialog(program: any = null): void
	{
		this.dialogData = {
			id: program?.id || null,
			hu: program?.programNameHU || null,
			ro: program?.programNameRO || null,
			category: program?.categoryId || null,
			venue: program?.venueId || null,
			date: program?.dateId || null,
			capacity: program?.totalPrioritySpots || 0,
			additionalCapacity: program?.totalWaitingListSpots || 0,
			startingTime: program?.startTime || "12:00:00",
			duration: program?.duration || 60,
			isForChild: program?.isForChild == 0 ? false : true,
			extraTicket: program?.extraTicket || false,
			ticketLink: program?.ticketLink || null,
		};
		this.showProgramDialog = true;
	}

	public async dialogClose(saveClicked: boolean): Promise<void>
	{
		if (saveClicked)
		{
			try
			{
				this.dialogDataSaveInProgress = true;
				this.dialogData.extraTicket = this.dialogData.ticketLink ? true : false;
				await programService.saveProgram(this.dialogData);
				toastService.showToast("Az esemeny mentese sikeres!", "success");
			}
			catch (error)
			{
				toastService.showToast("Az esemeny mentese sikertelen!", "danger");
			}
			this.dialogDataSaveInProgress = false;
			this.showProgramDialog = false;
			this.dialogData = null;
			await this.fetchPrograms();
		}
		else
		{
			this.showProgramDialog = false;
			this.dialogData = null;
		}
	}

	public async cloneEvent(): Promise<void>
	{
		try
		{
			this.dialogDataSaveInProgress = true;
			this.dialogData.extraTicket = this.dialogData.ticketLink ? true : false;
			this.dialogData.id = null;
			await programService.saveProgram(this.dialogData);
			toastService.showToast("Az esemeny mentese sikeres!", "success");
		}
		catch (error)
		{
			toastService.showToast("Az esemeny mentese sikertelen!", "danger");
		}
		this.dialogDataSaveInProgress = false;
		this.showProgramDialog = false;
		this.dialogData = null;
		await this.fetchPrograms();
	}
}
</script>

<style lang="scss" scoped></style>
