<template>
  <div class="programs-page">
    <div class="page-header">
      <div>{{ $t("programs.eventPrograms") }}</div>
      <div class="subheader" v-if="isAdmin && !isOfflineUserRoute">
        Admin preview
      </div>
      <div class="subheader" v-if="isAdmin && isOfflineUserRoute">
        Offline user: {{ offlineUser?.backupCode }}
      </div>
    </div>
    <div class="data-loader" v-if="userEventsLoadingInProgress">
      <ui-spinner active></ui-spinner>
      <span>{{ $t("programs.loadingData") }}</span>
    </div>
    <div
      class="navigation-list"
      v-if="!userEventsLoadingInProgress && (!isAdmin || isOfflineUserRoute)"
    >
      <ui-button raised @click="navigateToEventPass()">{{
        $t("programs.showEventPass")
      }}</ui-button>
    </div>
    <div class="programs-wrapper" v-if="!userEventsLoadingInProgress && tabs">
      <div class="programs-content">
        <ui-chips type="filter" v-model="selectedFilters">
          <div class="filter-label">
            <b>{{ $t("filter") }}:</b>
          </div>
          <ui-chip
            :class="{ active: isActiveFilter(category.value) }"
            v-for="category in categories"
            :key="category.value"
            @click="filteringChanged(category.value)"
          >
            {{ category[currentLanguage] }}
          </ui-chip>
        </ui-chips>
        <div class="tab-header">
          <ui-tab-bar v-model="activeTab">
            <ui-tab v-for="(tab, index) in tabs" :key="index"
              >{{ tab[currentLanguage] }} - {{ tab.date }}</ui-tab
            >
          </ui-tab-bar>
        </div>
        <div class="tab-content" v-if="tabs">
          <div
            class="event-location-item"
            v-for="location in tabs[activeTab]?.locations"
            :key="location"
          >
            <div class="location-name">
              <span>{{ location[`locationName${currentLanguage}`] }}</span>
              <ui-icon>event</ui-icon>
            </div>
            <div class="program-items">
              <template v-for="program in location.programs" :key="program">
                <div
                  class="program-item"
                  v-if="isActiveFilter(program.categoryId)"
                  :class="{
                    participating: program.currentUserPassType,
                    priority: program.currentUserPassType === 'PRIORITY',
                    waitinglist: program.currentUserPassType === 'WAITINGLIST',
                  }"
                >
                  <ui-checkbox
                    input-id="program"
                    v-model="program.currentUserRegistered"
                    v-on:change="programCheckChanged(program, persons)"
                    :disabled="programRegisterInProgress || (program.registrationStatus === 'CLOSE' && !program.currentUserPassType)"
                    v-if="!isAdmin || isOfflineUserRoute"
                  >
                  </ui-checkbox>
                  <div class="program-details">
                    <div class="program-title">
                      {{ program[`programName${currentLanguage}`] }}
                    </div>
                    <div class="secondary-info">
                      <span class="program-time"
                        >{{ program.startTime }} - {{ program.endTime }}</span 
                      >
                      <span
                        class="program-category"
                        v-if="program.categoryId"
                        >{{ program[`categoryName${currentLanguage}`] }}</span
                      >
                    </div>
                    <div class="totals">
                      {{
                        $t("programs.participantsTxt", {
                          participants: program.registeredPriority,
                          extraParticipants: program.registeredWaitingList,
                        })
                      }}
                    </div>
                    <div
                      class="totals"
                      v-html="getRegisteredTotalsText(program)"
                    ></div>
                    <div
                      v-if="
                        (!isAdmin || isOfflineUserRoute) &&
                        program.isForChild &&
                        !program.currentUserPassType &&
						totalPersons > 1
                      "
                    >
                      <ui-form-field>
                        <ui-textfield
                          class="participants-count-input"
                          :input-type="'number'"
                          :attrs="{
                            name: 'children',
                            min: 1,
                            max: totalPersons,
                          }"
                          v-model="program.participants"
                        ></ui-textfield>
                      </ui-form-field>
                      <span class="children-count"
                        >Résztvevő személyek száma</span
                      >
                    </div>
                    <div
                      class="description"
                      v-if="program.ticketLink"
                      v-html="getTicketLinkText(program.ticketLink)"
                    ></div>
                    <span
                      v-if="program.currentUserPassType === 'PRIORITY'"
                      class="pass-type priority"
                      >PRIORITY CHECKIN</span
                    >
                    <span
                      v-if="program.currentUserPassType === 'WAITINGLIST'"
                      class="pass-type wait-list"
                      >WAITING LIST</span
                    >
                    <span
                      class="children-count"
                      v-if="program.currentUserPassType && program.isForChild"
                      >{{ program?.children || "0" }} Résztvevő</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation-list" v-if="!isAdmin || isOfflineUserRoute">
      <ui-button raised @click="navigateToEventPass()">{{
        $t("programs.showEventPass")
      }}</ui-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import offlineUserService from "../services/offline-user.service";
import toastService from "../services/toast.service";
import categoryService from "../services/category.service";
import Constants from "@/core/Constants";
import moment from "moment";

@Options({
  components: {},
})
export default class ProgramList extends Vue {
  public persons = 1;
  public offlineUser: any = null;
  public isOfflineUserRoute = false;
  public userEventsLoadingInProgress = false;
  public programRegisterInProgress = false;
  public activeTab = 0;
  public tabs: { HU: string; RO: string; date: string; locations: any[] }[] =
    [];
  public categories: { HU: string; RO: string; value: number }[] = [];
  public categoryActivityMap: Map<number, boolean> = new Map();
  public selectedFilters: number[] = [];

  public get isAdmin(): boolean {
    return userService?.localUserDetails?.type === "ADMIN";
  }

  public get currentLanguage(): string {
    return this.$i18n.locale?.toUpperCase();
  }

  public get children() {
    if (this.isAdmin) return this.offlineUser?.children;
    else return userService.localUserDetails?.children;
  }

  public get totalPersons() {
    if (this.isAdmin) return this.offlineUser?.children + 1;
    else return userService.localUserDetails?.children + 1;
  }

  mounted(): void {
    this.loadUserEvents();
    this.loadCategories();
  }

  private async checkResolveOfflineUser(): Promise<void> {
    const routeParams = this.$route?.params;
    if (routeParams?.backupCode && this.isAdmin) {
      this.isOfflineUserRoute = true;
      try {
        const offlineUser = await offlineUserService.searchOfflineUser(
          routeParams.backupCode
        );
        if (offlineUser?.data?.backupCode) {
          this.offlineUser = offlineUser.data;
        }
      } catch (error) {
        toastService.showToast(
          this.$t("errorMsgs.offlineUserLoadError"),
          "danger"
        );
        this.$router.push("/admin-offline-guests");
      }
    }
  }

  public async loadCategories(): Promise<void> {
    try {
      const categories = await categoryService.getCategories();
      categories?.data.forEach((category) => {
        this.categories.push({
          HU: category.hu,
          RO: category.ro,
          value: category.id,
        });
        this.categoryActivityMap.set(category.id, true);
        this.selectedFilters.push(this.selectedFilters.length);
      });
    } catch (error) {
      //
    }
  }

  public async loadUserEvents(): Promise<void> {
    try {
      this.offlineUser = null;
      this.isOfflineUserRoute = false;
      this.userEventsLoadingInProgress = true;
      let userId = this.isAdmin ? userService.localUserDetails?.id : null;
      await this.checkResolveOfflineUser();
      if (this.isOfflineUserRoute) {
        userId = this.offlineUser?.id;
        if (!userId) {
          toastService.showToast(
            this.$t("errorMsgs.offlineUserLoadError"),
            "danger"
          );
          this.$router.push("/admin-offline-guests");
        }
      }
      const response = await userService.getUserEvents(userId);
      if (response?.data) {
        response.data?.days.forEach((date) => {
          date.locations.forEach((location) => {
            location.programs.forEach((program) => {
              //We add this property so we can use it for program checkbox true/false tracking
              program["currentUserRegistered"] =
                program.registerPosition > 0 && program.currentUserPassType
                  ? true
                  : false;
              program["participants"] = this.totalPersons;
            });
          });

          this.tabs.push({
            HU: date.dateNameHU,
            RO: date.dateNameRO,
            date: moment(date.date).format("ll"),
            locations: date.locations,
          });
        });
      }
    } catch (error) {
      //
    }
    this.userEventsLoadingInProgress = false;
  }

  public async programCheckChanged(program): Promise<void> {
    this.programRegisterInProgress = true;
    try {
      const userId = this.offlineUser?.id || null;
      if (program?.registerPosition > 0 && program.currentUserPassType) {
        await userService.deregisterUserEvent(program.id, userId);
        toastService.showToast(
          this.$t("programs.deRegisterSuccessMsg", {
            program: program[`programName${this.currentLanguage}`],
          }),
          "info"
        );
        program.currentUserPassType = null;
        program.registerPosition = null;
      } else {
        if (program.participants > this.totalPersons) {
          toastService.showToast(
            this.$t("programs.maxParticipantsOverwritten"),
            "warning"
          );
          program.participants = this.totalPersons; 
        }
        const register = await userService.registerUserEvent(
          program.id,
          userId,
          program.participants,
          this.$i18n.locale
        );
        toastService.showToast(
          this.$t("programs.registerSuccessMsg", {
            program: program[`programName${this.currentLanguage}`],
          }),
          "success"
        );
        program.currentUserPassType = register.data.registrationType;
        program.registerPosition = register.data.registerPosition;
        program.children = program.participants;
      }
    } catch (error) {
      program.currentUserRegistered = !program.currentUserRegistered;
      toastService.showToast(this.$t("programs.programUpdateError"), "danger");
    }
    this.programRegisterInProgress = false;
  }

  public navigateToEventPass(): void {
    this.$router.push(
      this.isOfflineUserRoute
        ? `/offlineuser/event-pass/${this.offlineUser.backupCode}`
        : "/event-pass"
    );
  }

  public getTicketLinkText(link: string): string {
    return this.$t("programs.ticketLinkTxt", { link: link });
  }

  public getRegisteredTotalsText(program): string {
    return this.$t("programs.registeredTxt", {
      participants: program.totalRegistered,
      totalSpots: program.totalPrioritySpots,
      extraSpots: program.totalWaitingListSpots,
    });
  }

  public filteringChanged(category): void {
    const oldValue = this.categoryActivityMap.get(category);
    this.categoryActivityMap.set(category, !oldValue);
  }

  public isActiveFilter(category): boolean {
    return this.categoryActivityMap.get(category) || false;
  }
}
</script>

<style lang="scss" scoped>
$borderColor: #36305e;

.active {
  background: #fed001;
}

.filter-label {
  line-height: 40px;
  padding-left: 5px;
}

div.programs-page {
  div.programs-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    div.programs-content {
      width: 100%;
      max-width: 1200px;
      div.tab-content {
        padding: 1rem 5px;
        div.event-location-item {
          border: 1px solid #dedede;
          border-radius: 0.5rem;
          overflow: hidden;
          box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
            rgb(0 0 0 / 30%) 0px 3px 7px -3px;
          -moz-box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
            rgb(0 0 0 / 30%) 0px 3px 7px -3px;
          -webkit-box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
            rgb(0 0 0 / 30%) 0px 3px 7px -3px;
          &:not(:first-child) {
            margin-top: 1rem;
          }
          div.location-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;
            font-weight: 600;
            padding: 1rem 0.8rem;
            border-bottom: 2px solid $borderColor;
          }
          div.program-items {
            div.program-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              border-bottom: 1px solid $borderColor;
              padding: 0.2rem 1rem 0.2rem 1rem;

              &:last-of-type {
                border-bottom: none;
              }

              &.priority {
                background: #e8f5e9;
              }

              &.waitinglist {
                background: #fff3e0;
              }

              .participants-count-input {
                height: 40px;
              }

              div.program-details {
                width: 100%;
                > div {
                  padding: 0.1rem;
                }
                div.program-title {
                  color: $basePurple;
                  font-weight: 600;
                }
                div.secondary-info {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  > span {
                    display: flex;
                    align-items: center;
                  }
                  .program-category {
                    border: 1px solid #36305e;
                    border-radius: 0.2rem;
                    padding: 0.15rem 0.3rem;
                    background: #fed001;
                    font-weight: 500;
                    font-size: 0.8rem;
                  }
                }
                span.children-count {
                  padding: 0.1rem 0.3rem;
                  border-radius: 0.2rem;
                  font-size: 0.9rem;
                  display: inline-block;
                  margin: 0.4rem 0;
                  border: 1px solid #36305e;
                  background: #ffffff;
                  color: black;
                  margin-left: 1rem;
                }
                div.totals {
                  font-size: 0.9rem;
                  color: #616161;
                }
              }
            }
          }
        }
      }
    }
  }
  div.navigation-list {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 2rem;
  }
}
</style>
