import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';


class AdminUsersService {
    getAdminUsers() {
        return axios.get(Constants.API_URL + 'api/admins.json', authHeader());
    }

    saveAdminUser(adminUser) {
        return axios.post(Constants.API_URL + 'api/admin.json', adminUser, authHeader()); 
    }

    deleteAdminUser(adminUserId) {
        return axios.delete(Constants.API_URL + `api/admin/${adminUserId}.json`, authHeader());  
    }
}

export default new AdminUsersService();