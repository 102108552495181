import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';


class EventDateService {
    getEventDays() {
        return axios.get(Constants.API_URL + 'api/dates.json', authHeader());
    }

    saveEventDay(eventDay) {
        return axios.post(Constants.API_URL + 'api/date.json', eventDay, authHeader());
    }

    deleteEventDay(eventDayId) {
        return axios.delete(Constants.API_URL + `api/date/${eventDayId}.json`, authHeader()); 
    }
}

export default new EventDateService(); 