
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import toastService from "../services/toast.service";

@Options({
  components: {},
})
export default class PasswordReset extends Vue {
  public userId: any = null;
  public hash: any = null;
  public password: any = null;
  public confirmPassword: any = null;
  public passwordResetInProgress: any = false;
  public errorMessage: any = "";

  async mounted(): Promise<void> {
    const routeParams = this.$route?.params;
    if (routeParams?.userId && routeParams?.hash) {
      this.userId = routeParams.userId;
      this.hash = routeParams.hash;
    } else {
      toastService.showToast(
        this.$t("passwordReset.missingParametersError"),
        "danger"
      );
      this.$router.push("/login");
    }
  }

  public async changePassword(): Promise<void> {
    if (this.password.length < 8) {
      toastService.showToast(
        this.$t("passwordReset.passwordMin8CharacterError"), 
        "warning"
      );
      return;
    }
    if (this.password != this.confirmPassword) {
      toastService.showToast(
        this.$t("passwordReset.passwordConfirmMismatchError"),
        "warning"
      );
      return;
    }
    try {
      this.passwordResetInProgress = true;
      await userService.changePassword(
        this.userId,
        this.hash,
        this.password,
        this.confirmPassword
      );
      toastService.showToast(
        this.$t("passwordReset.changeSuccessMsg"),
        "success"
      );
      this.$router.push("/login");
    } catch (error) {
      if (error.response) {
        this.errorMessage = this.$t("passwordReset.passwordChangeError");
      }
    }
    this.passwordResetInProgress = false;
  }

  public navigateToLogin(): void {
    this.$router.push("/login");
  }
}
