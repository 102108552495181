
import programService from "@/services/program.service";
import { Options, Vue } from "vue-class-component";
import toastService from "../../services/toast.service";
import userValidationService from "../../services/user-validation.service";

enum CheckStatus
{
	Validating = "VALIDATING",
	Valid = "VALID",
	Invalid = "INVALID",
	ApiError = "APIERROR",
}

interface IUserCheck
{
	backupCode: string;
	status: CheckStatus;
	message: string;
}

Options({});
export default class EventCheckIn extends Vue
{
	public checkStatus = CheckStatus;
	public checkType = "1";
	public additionalDataLoadingInProgress = false;
	public events: { label: string; value: number }[] = [];
	public codeInsertInProgress = false;
	public event: any = null;
	public backupCode: any = null;
	public userCheckInList: string[] = [];
	public userCheckInMap: Map<string, IUserCheck> = new Map();
	public userCheckOutList: string[] = [];
	public userCheckOutMap: Map<string, IUserCheck> = new Map();

	async created(): Promise<void>
	{
		await this.fetchAdditionlData();
	}

	public checkInOut(): void
	{
		if (!this.backupCode || this.backupCode === "" || this.codeInsertInProgress)
		{
			return;
		}

		this.codeInsertInProgress = true;
		if (this.checkType === "1")
		{
			this.userCheckOutList = [];
			this.userCheckOutMap = new Map();
			if (this.userCheckInList[0] !== this.backupCode)
			{
				this.checkInCode(this.backupCode);
			}
			else
			{
				toastService.showToast("Code already scanned.", "warning");
				this.backupCode = "";
			}
		}
		else
		{
			if (this.checkType === "2")
			{
				this.userCheckInList = [];
				this.userCheckInMap = new Map();
				if (this.userCheckOutList[0] !== this.backupCode)
				{
					this.checkOutCode(this.backupCode);
				}
				else
				{
					toastService.showToast("Code already scanned.", "warning");
					this.backupCode = "";
				}
			}
		}

		this.codeInsertInProgress = false;
	}

	private async checkInCode(backupCode: string): Promise<void>
	{
		try
		{
			this.userCheckInList.unshift(backupCode);
			this.userCheckInMap.set(backupCode, {
				backupCode: backupCode,
				status: CheckStatus.Validating,
				message: "",
			});
			this.userCheckInList = this.userCheckInList.slice(0, 10);
			const user = await userValidationService.searchUser(backupCode);
			const qrValidation = await userValidationService.validateQR(user.data.UUID, this.event);
			const validate = await userValidationService.checkin(user.data.UUID, this.event);

			if (validate && validate.data?.id > 0)
			{
				this.userCheckInMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Valid,
					message: "",
				});
			}
			else
			{
				this.userCheckInMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Invalid,
					message: validate.data?.error || "",
				});
			}
		}
		catch (error)
		{
			this.userCheckInMap.set(backupCode, {
				backupCode: backupCode,
				status: error.response.data?.error ? CheckStatus.Invalid : CheckStatus.ApiError,
				message: error.response.data?.error || "",
			});
		}

		this.backupCode = "";
	}

	private async checkOutCode(backupCode: string): Promise<void>
	{
		try
		{
			this.userCheckOutList.unshift(backupCode);
			this.userCheckOutMap.set(backupCode, {
				backupCode: backupCode,
				status: CheckStatus.Validating,
				message: "",
			});
			this.userCheckOutList = this.userCheckOutList.slice(0, 10);
			const user = await userValidationService.searchUser(backupCode);
			const qrValidation = await userValidationService.validateQR(user.data.UUID, this.event);
			const validate = await userValidationService.checkout(user.data.UUID, this.event);
			if (validate && validate.data?.success)
			{
				this.userCheckOutMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Valid,
					message: "",
				});
			}
			else
			{
				this.userCheckOutMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Invalid,
					message: validate.data?.error || "",
				});
			}
		}
		catch (error)
		{
			this.userCheckOutMap.set(backupCode, {
				backupCode: backupCode,
				status: error.response.data?.error ? CheckStatus.Invalid : CheckStatus.ApiError,
				message: error.response.data?.error || "",
			});
		}

		this.backupCode = "";
	}

	private async fetchAdditionlData(): Promise<void>
	{
		this.additionalDataLoadingInProgress = true;
		try
		{
			this.events = [];
			const events = await programService.getConcertList();
			events?.data.forEach((event) => {
				this.events.push({
					label: event.hu,
					value: event.id,
				});
			});
		}
		catch (error)
		{
			toastService.showToast("Failed to load event list.", "danger");
		}

		this.additionalDataLoadingInProgress = false;
	}
}
