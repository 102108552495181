<template>
	<div class="admin-list-page">
		<div class="page-header">Event Check In</div>
		<div class="admin-page-content">
			<div class="data-loader" v-if="additionalDataLoadingInProgress">
				<ui-spinner active></ui-spinner>
				<span>{{ $t("programs.loadingData") }}</span>
			</div>
			<div class="check-form-wrapper" v-if="!additionalDataLoadingInProgress">
				<div class="radio-field-wrapper">
					<ui-form-field>
						<ui-radio v-model="checkType" input-id="checkin" name="checkinout" value="1"></ui-radio>
						<label for="checkin">Check In</label>
					</ui-form-field>
					<ui-form-field>
						<ui-radio v-model="checkType" input-id="checkout" name="checkinout" value="2"></ui-radio>
						<label for="checkout">Check Out</label>
					</ui-form-field>
				</div>
				<div class="checkin-field-wrapper">
					<ui-select v-model="event" :options="events" outlined>Event</ui-select>
				</div>
				<div class="checkin-field-wrapper">
					<ui-textfield outlined v-model="backupCode" @keyup.enter="checkInOut()">Code</ui-textfield>
					<ui-button raised :disabled="!backupCode" @click="checkInOut()">
						<span>Validate</span>
						<!-- <ui-spinner active v-if="offlineUserLoadingInProgress"></ui-spinner> -->
					</ui-button>
				</div>
			</div>
			<div class="checked-users-wrapper" v-if="checkType === '1'">
				<div class="user-validation-item" v-for="hash of userCheckInList" :key="hash">
					<div class="main-data flex-row flex-gap-10">
						<div>{{ hash }}</div>
						<div class="entry-validation-progress flex-row flex-gap-10" v-if="userCheckInMap.get(hash)?.status === checkStatus.Validating">
							<ui-spinner active size="S"></ui-spinner>
							<span>Validating...</span>
						</div>
						<div class="entry-status flex-row flex-gap-10" style="color: green" v-if="userCheckInMap.get(hash)?.status === checkStatus.Valid">
							<ui-icon>check_circle</ui-icon>
							<span>Checked In</span>
						</div>
						<div class="entry-status flex-row flex-gap-10" style="color: red" v-if="userCheckInMap.get(hash)?.status === checkStatus.Invalid">
							<ui-icon>do_disturb_on</ui-icon>
							<span>Invalid</span>
						</div>
						<div class="entry-status flex-row flex-gap-10" style="color: red" v-if="userCheckInMap.get(hash)?.status === checkStatus.ApiError">
							<ui-icon>error</ui-icon>
							<span>API Error</span>
						</div>
					</div>
					<div v-if="userCheckInMap.get(hash)?.message" class="validation-message">Error: {{ userCheckInMap.get(hash)?.message }}</div>
				</div>
			</div>
			<div class="checked-users-wrapper" v-if="checkType === '2'">
				<div class="user-validation-item" v-for="hash of userCheckOutList" :key="hash">
					<div class="main-data flex-row flex-gap-10">
						<div>{{ hash }}</div>
						<div class="entry-validation-progress flex-row flex-gap-10" v-if="userCheckOutMap.get(hash)?.status === checkStatus.Validating">
							<ui-spinner active size="S"></ui-spinner>
							<span>Validating...</span>
						</div>
						<div class="entry-status flex-row flex-gap-10" style="color: green" v-if="userCheckOutMap.get(hash)?.status === checkStatus.Valid">
							<ui-icon>check_circle</ui-icon>
							<span>Checked Out</span>
						</div>
						<div class="entry-status flex-row flex-gap-10" style="color: red" v-if="userCheckOutMap.get(hash)?.status === checkStatus.Invalid">
							<ui-icon>do_disturb_on</ui-icon>
							<span>Invalid</span>
						</div>
						<div class="entry-status flex-row flex-gap-10" style="color: red" v-if="userCheckOutMap.get(hash)?.status === checkStatus.ApiError">
							<ui-icon>error</ui-icon>
							<span>API Error</span>
						</div>
					</div>
					<div v-if="userCheckOutMap.get(hash)?.message" class="validation-message">Error: {{ userCheckOutMap.get(hash)?.message }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import programService from "@/services/program.service";
import { Options, Vue } from "vue-class-component";
import toastService from "../../services/toast.service";
import userValidationService from "../../services/user-validation.service";

enum CheckStatus
{
	Validating = "VALIDATING",
	Valid = "VALID",
	Invalid = "INVALID",
	ApiError = "APIERROR",
}

interface IUserCheck
{
	backupCode: string;
	status: CheckStatus;
	message: string;
}

Options({});
export default class EventCheckIn extends Vue
{
	public checkStatus = CheckStatus;
	public checkType = "1";
	public additionalDataLoadingInProgress = false;
	public events: { label: string; value: number }[] = [];
	public codeInsertInProgress = false;
	public event: any = null;
	public backupCode: any = null;
	public userCheckInList: string[] = [];
	public userCheckInMap: Map<string, IUserCheck> = new Map();
	public userCheckOutList: string[] = [];
	public userCheckOutMap: Map<string, IUserCheck> = new Map();

	async created(): Promise<void>
	{
		await this.fetchAdditionlData();
	}

	public checkInOut(): void
	{
		if (!this.backupCode || this.backupCode === "" || this.codeInsertInProgress)
		{
			return;
		}

		this.codeInsertInProgress = true;
		if (this.checkType === "1")
		{
			this.userCheckOutList = [];
			this.userCheckOutMap = new Map();
			if (this.userCheckInList[0] !== this.backupCode)
			{
				this.checkInCode(this.backupCode);
			}
			else
			{
				toastService.showToast("Code already scanned.", "warning");
				this.backupCode = "";
			}
		}
		else
		{
			if (this.checkType === "2")
			{
				this.userCheckInList = [];
				this.userCheckInMap = new Map();
				if (this.userCheckOutList[0] !== this.backupCode)
				{
					this.checkOutCode(this.backupCode);
				}
				else
				{
					toastService.showToast("Code already scanned.", "warning");
					this.backupCode = "";
				}
			}
		}

		this.codeInsertInProgress = false;
	}

	private async checkInCode(backupCode: string): Promise<void>
	{
		try
		{
			this.userCheckInList.unshift(backupCode);
			this.userCheckInMap.set(backupCode, {
				backupCode: backupCode,
				status: CheckStatus.Validating,
				message: "",
			});
			this.userCheckInList = this.userCheckInList.slice(0, 10);
			const user = await userValidationService.searchUser(backupCode);
			const qrValidation = await userValidationService.validateQR(user.data.UUID, this.event);
			const validate = await userValidationService.checkin(user.data.UUID, this.event);

			if (validate && validate.data?.id > 0)
			{
				this.userCheckInMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Valid,
					message: "",
				});
			}
			else
			{
				this.userCheckInMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Invalid,
					message: validate.data?.error || "",
				});
			}
		}
		catch (error)
		{
			this.userCheckInMap.set(backupCode, {
				backupCode: backupCode,
				status: error.response.data?.error ? CheckStatus.Invalid : CheckStatus.ApiError,
				message: error.response.data?.error || "",
			});
		}

		this.backupCode = "";
	}

	private async checkOutCode(backupCode: string): Promise<void>
	{
		try
		{
			this.userCheckOutList.unshift(backupCode);
			this.userCheckOutMap.set(backupCode, {
				backupCode: backupCode,
				status: CheckStatus.Validating,
				message: "",
			});
			this.userCheckOutList = this.userCheckOutList.slice(0, 10);
			const user = await userValidationService.searchUser(backupCode);
			const qrValidation = await userValidationService.validateQR(user.data.UUID, this.event);
			const validate = await userValidationService.checkout(user.data.UUID, this.event);
			if (validate && validate.data?.success)
			{
				this.userCheckOutMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Valid,
					message: "",
				});
			}
			else
			{
				this.userCheckOutMap.set(backupCode, {
					backupCode: backupCode,
					status: CheckStatus.Invalid,
					message: validate.data?.error || "",
				});
			}
		}
		catch (error)
		{
			this.userCheckOutMap.set(backupCode, {
				backupCode: backupCode,
				status: error.response.data?.error ? CheckStatus.Invalid : CheckStatus.ApiError,
				message: error.response.data?.error || "",
			});
		}

		this.backupCode = "";
	}

	private async fetchAdditionlData(): Promise<void>
	{
		this.additionalDataLoadingInProgress = true;
		try
		{
			this.events = [];
			const events = await programService.getConcertList();
			events?.data.forEach((event) => {
				this.events.push({
					label: event.hu,
					value: event.id,
				});
			});
		}
		catch (error)
		{
			toastService.showToast("Failed to load event list.", "danger");
		}

		this.additionalDataLoadingInProgress = false;
	}
}
</script>

<style lang="scss" scoped>
div.check-form-wrapper {
  max-width: 450px;
  width: 100%;
}
div.checkin-field-wrapper,
div.radio-field-wrapper {
  width: 100%;
  max-width: 450px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: row wrap;
  align-items: center;
  box-sizing: border-box;
}

div.checkin-field-wrapper {
  .mdc-form-field,
  .mdc-text-field {
    min-width: 250px;
    max-width: 400px;
    width: 100%;
  }
  button {
    width: 170px;
  }
}

div.checked-users-wrapper {
  width: 100%;
  max-width: 700px;
  padding: 2rem 1rem;
  box-sizing: border-box;
  div.user-validation-item {
    margin: 1rem 0;
    border: 1px solid #36305e;
    border-radius: 4px;
    padding: 1rem;
    .main-data {
      justify-content: space-between;
    }
    div.validation-message {
      color: red;
      font-weight: 600;
      margin-top: 0.5rem;
    }
  }
}
</style>
