import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';


class CategoryService {
    getCategories() {
        return axios.get(Constants.API_URL + 'api/categories.json', authHeader());
    }

    saveCategory(category) {
        return axios.post(Constants.API_URL + 'api/category.json', category, authHeader()); 
    }

    deleteCategory(categoryId) {
        return axios.delete(Constants.API_URL + `api/category/${categoryId}.json`, authHeader()); 
    }
}

export default new CategoryService();