<template>
  <div class="admin-list-page">
    <div class="page-header">
      {{ $t("eventDate.eventDates") }}
      <span v-if="eventDays">({{ eventDays.length }})</span>
    </div>
    <div class="admin-page-content">
      <div class="list-wrapper">
        <div class="data-loader" v-if="dataLoadingInProgress">
          <ui-spinner active></ui-spinner>
          <span>{{ $t("eventDate.loadingData") }}</span>
        </div>
        <ui-table
          v-if="eventDays && !dataLoadingInProgress"
          v-model="selectedRows"
          fullwidth
          :data="eventDays"
          :thead="tHead"
          :tbody="tBody"
          row-checkbox
          selected-key="id"
        >
          <template #actions="{ data }">
            <ui-icon @click="editClicked(data)">edit</ui-icon>
            <ui-icon @click="deleteClicked(data)">delete</ui-icon>
          </template>
        </ui-table>
      </div>
      <div class="list-footer">
        <ui-button
          raised
          :disabled="dataLoadingInProgress || dataFetchError"
          @click="openEventDayDialog(null)"
        >
          <ui-icon>add_circle_outline</ui-icon>
          <span>{{ $t("eventDate.new") }}</span>
        </ui-button>
      </div>

      <!--
      ********************************************************************************************************************
      *
      *   EVENT eventDays DIALOG
      *
      ********************************************************************************************************************
      -->
      <ui-dialog
        v-model="showEventDayDialog"
        @confirm="dialogClose"
        :closable="false"
      >
        <ui-dialog-title>{{
          dialogData?.id ? $t("eventDate.edit") : $t("eventDate.add")
        }}</ui-dialog-title>
        <ui-dialog-content>
          <ui-form
            type="1"
            item-margin-bottom="16"
            action-align="center"
            v-if="dialogData"
          >
            <ui-form-field class="text-field required">
              <ui-textfield
                outlined
                v-model="dialogData.hu"
                :attrs="{ name: 'nameHU' }"
                required
                >{{ $t("eventDate.nameHU") }}</ui-textfield
              >
            </ui-form-field>
            <ui-form-field>
              <ui-textfield
                outlined
                v-model="dialogData.ro"
                :attrs="{ name: 'nameRO' }"
                required
                >{{ $t("eventDate.nameRO") }}</ui-textfield
              >
            </ui-form-field>
            <ui-form-field>
              <ui-datepicker
                v-model="dialogData.day"
                :config="datePickerConfig"
                placeholder="Select Date.."
                toggle
                clear
              ></ui-datepicker>
            </ui-form-field>
          </ui-form>
        </ui-dialog-content>
        <ui-dialog-actions :acceptText="'Save'">
          <ui-button
            @click="dialogClose(false)"
            :disabled="dialogDataSaveInProgress"
            >{{ $t("cancel") }}</ui-button
          >
          <ui-button
            raised
            @click="dialogClose(true)"
            :disabled="dialogDataSaveInProgress"
          >
            <span class="flex-center-10" v-if="dialogDataSaveInProgress">
              <ui-spinner active size="small"></ui-spinner>
              <span>{{ $t("save") }}...</span>
            </span>
            <span v-if="!dialogDataSaveInProgress">{{ $t("save") }}</span>
          </ui-button>
        </ui-dialog-actions>
      </ui-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import toastService from "../../services/toast.service";
import eventDateService from "../../services/event-date.service";
import _ from "lodash";

Options({});
export default class EventDays extends Vue {
  //#region Init
  async created(): Promise<void> {
    await this.fetchEventDays();
  }
  //#endregion

  //#region Properties
  public datePickerConfig = {
    defaultDate: "today",
    dateFormat: "Y-m-d",
  };
  public showEventDayDialog = false;
  public dialogDataSaveInProgress = false;
  public dialogData = null;

  public dataLoadingInProgress = false;
  public dataLoadingFinished = false;
  public dataFetchError = false;
  public selectedRows = [];
  public eventDays = null;
  public tHead = ["ID", "Name HU", "Name RO", "Date", { value: "Actions", align: "center" }];
  public tBody = [
    "id",
    "hu",
    "ro",
    "day",
    {
      slot: "actions",
      align: "center"
    },
  ];
  //#endregion

  //#region Helper methods
  public async fetchEventDays(): Promise<void> {
    this.dataLoadingInProgress = true;
    this.dataLoadingFinished = false;
    this.dataFetchError = false;
    this.eventDays = null;
    try {
      const eventDays = await eventDateService.getEventDays();
      this.eventDays = eventDays.data;
      this.dataLoadingFinished = true;
    } catch (error) {
      this.dataFetchError = true;
    }
    this.dataLoadingInProgress = false;
  }
  //#endregion

  //#region List methods
  public editClicked(eventDay): void {
    this.openEventDayDialog(eventDay);
  }

  public deleteClicked(eventDay): void {
    if (!eventDay?.id) {
      return;
    }
    this.$confirm({
      title: `${eventDay.hu}`,
      message: `Bisztosan ki akarja torolni a datumot? Hogyha a datumhoz vanak hozakotve esemenyek akkor az esemenyek is kitorlodnek!`,
      state: "warning",
      acceptText: "Igen",
      cancelText: "Nem",
    }).then(async (result) => {
      if (result) {
        try {
          await eventDateService.deleteEventDay(eventDay?.id);
          toastService.showToast("A datum torlese sikeres!", "success");
          this.fetchEventDays();
        } catch (error) {
          toastService.showToast("A datum torlese sikertelen!", "danger");
        }
      }
    });
  }
  //#endregion

  //#region Dialog methods
  public openEventDayDialog(eventDate = null): void {
    this.dialogData = _.cloneDeep(eventDate) || {
      id: null,
      hu: null,
      ro: null,
      day: null
    };
    this.showEventDayDialog = true;
  }

  public async dialogClose(saveClicked: boolean): Promise<void> {
    if (saveClicked) {
      try {
        this.dialogDataSaveInProgress = true;
        await eventDateService.saveEventDay(this.dialogData);
      } catch (error) {
        toastService.showToast("A datum mentese sikertelen!", "danger");
      }
      this.dialogDataSaveInProgress = false;
      this.showEventDayDialog = false;
      this.dialogData = null;
      await this.fetchEventDays();
    } else {
      this.showEventDayDialog = false;
      this.dialogData = null;
    }
  }
  //#endregion
}
</script>

<style lang="scss" scoped></style>
