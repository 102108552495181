
import { Options, Vue } from "vue-class-component";
import _ from "lodash";
import venueService from "../../services/venue.service";
import toastService from "@/services/toast.service";

@Options({
  components: {},
})
export default class EventLocations extends Vue {
  //#region Init
  async created(): Promise<void> {
    await this.fetchEventLocations();
  }
  //#endregion

  //#region Properties
  public showEventLocationDialog = false;
  public dialogDataSaveInProgress = false;
  public dialogData = null;

  public dataLoadingInProgress = false;
  public dataLoadingFinished = false;
  public dataFetchError = false;
  public selectedRows = [];
  public eventLocations = null;
  public tHead = ["ID", "Name HU", "Name RO", { value: "Actions", align: "center" }];
  public tBody = [
    "id",
    "hu",
    "ro",
    {
      slot: "actions",
      align: "center"
    },
  ];
  //#endregion

  //#region Helper methods
  public async fetchEventLocations(): Promise<void> {
    this.dataLoadingInProgress = true;
    this.dataLoadingFinished = false;
    this.dataFetchError = false;
    this.eventLocations = null;
    try {
      const venues = await venueService.getVenues();
      this.eventLocations = venues.data;
      this.dataLoadingFinished = true;
    } catch (error) {
      this.dataFetchError = true;
    }
    this.dataLoadingInProgress = false;
  }
  //#endregion

  //#region List methods
  public editClicked(eventLocation): void {
    this.openEventLocationDialog(eventLocation);
  }

  public deleteClicked(eventLocation): void {
    if (!eventLocation?.id) {
      return;
    }
    this.$confirm({
      title: `${eventLocation.hu}`,
      message: `Bisztosan ki akarja törölni a helyszint?`,
      state: "warning",
      acceptText: "Igen",
      cancelText: "Nem",
    }).then(async (result) => {
      if (result) {
        try {
          await venueService.deleteVenue(eventLocation?.id);
          toastService.showToast("A helyszín törlése sikeres!", "success");
          this.fetchEventLocations();
        } catch (error) {
          toastService.showToast("A helyszín törlése sikertelen!", "danger");
        }
      }
    });
  }
  //#endregion

  //#region Dialog methods
  public openEventLocationDialog(eventLocation = null): void {
    this.dialogData = _.cloneDeep(eventLocation) || {
      id: null,
      hu: null,
      ro: null,
    };
    this.showEventLocationDialog = true;
  }

  public async dialogClose(saveClicked: boolean): Promise<void> {
    if (saveClicked) {
      try {
        this.dialogDataSaveInProgress = true;
        await venueService.saveVenue(this.dialogData);
      } catch (error) {
        toastService.showToast("A helyszin mentese sikertelen!", "danger");
      }
      this.dialogDataSaveInProgress = false;
      this.showEventLocationDialog = false;
      this.dialogData = null;
      await this.fetchEventLocations();
    } else {
      this.showEventLocationDialog = false;
      this.dialogData = null;
    }
  }
  //#endregion
}
