
import { Options, Vue } from "vue-class-component";
import statisticsService from "../../../services/statistics.service";

Options({});
export default class TopRegistrants extends Vue
{
	async created(): Promise<void>
	{
		await this.LoadData();
	}

	public dataLoadingInProgress = false;
	public dataLoadingFinished = false;
	public dataFetchError = false;
	public topRegistrants = null;
	public tHead = ["Felhaszáló", "regisztrációk"];
	public tBody = ["backupCode", "registrations"];

	public async LoadData(): Promise<void>
	{
		this.dataLoadingInProgress = true;
		this.dataLoadingFinished = false;
		this.dataFetchError = false;
		this.topRegistrants = null;
		try
		{
			const topRegistrants = await statisticsService.GetTopRegistrants ();
			this.topRegistrants = topRegistrants.data;
			this.dataLoadingFinished = true;
		}
		catch (error)
		{
			this.dataFetchError = true;
		}
		this.dataLoadingInProgress = false;
	}
}
