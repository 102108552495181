<template>
  <div class="programs-page">
    <div class="page-header no-print">
      <div>{{$t("eventPass.eventPass")}}</div>
      <div class="subheader" v-if="isOfflineUserRoute">
        Offline user: {{ offlineUser?.backupCode }}
      </div>
    </div>
    <div class="data-loader" v-if="dataLoadingInProgress">
      <ui-spinner active></ui-spinner>
      <span>{{$t("eventPass.loadingData")}}</span>
    </div>
    <div v-if="!dataLoadingInProgress">
      <div id="printContent">
        <div class="event-pass-wrapper" v-if="qrCodeValue">
          <qrcode-vue
            :value="qrCodeValue"
            :size="350"
            level="H"
            id="qrCode"
          ></qrcode-vue>
        </div>
        <div class="qr-code-hash">Backup Code: {{ userBackupCode }}</div>
      </div>
      <div class="actions-list no-print">
        <ui-button raised @click="printPage()">{{$t("eventPass.print")}}</ui-button>
        <ui-button raised @click="goToProgramsList()">{{$t('programs.eventPrograms')}}</ui-button>
      </div>
    </div>
    <div class="event-pass-wrapper">
      <canvas id="barcode"></canvas>
    </div>
    <div v-if="!dataLoadingInProgress">
      <ui-divider
        v-if="programsList?.length > 0"
        :class="{ 'no-print': !isOfflineUserRoute }"
        >{{$t('eventPass.myEvents')}}</ui-divider
      >
      <div
        class="program-preview-wrapper"
        :class="{ 'no-print': !isOfflineUserRoute }"
      >
        <div class="programs-list-preview" v-if="programsList?.length > 0">
          <div
            class="program-date-group"
            v-for="group in programsList"
            :key="group"
          >
            <div class="group-title">
              {{ group.date[`dateName${currentLanguage}`] }} -
              {{ group.date.date }}
            </div>
            <div class="programs-list">
              <div
                class="program-item"
                v-for="program in group.programs"
                :key="program"
              >
                <div>
                  {{ program[`programName${currentLanguage}`] }} -
                  {{ program[`categoryName${currentLanguage}`] }} 
                </div>
                <div
                  v-if="program.currentUserPassType === 'PRIORITY'"
                  class="pass-type priority"
                >
                  PRIORITY CHECKIN
                </div>
                <div
                  v-if="program.currentUserPassType === 'WAITINGLIST'"
                  class="pass-type wait-list"
                >
                  WAITING LIST
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import QrcodeVue from "qrcode.vue";
import userService from "../services/user.service";
import offlineUserService from "../services/offline-user.service";
import toastService from "../services/toast.service";
import JsBarcode from "jsbarcode";

interface IProgramDayGroup {
  date: IProgramDate;
  programs: IProgramItem[];
}

interface IProgramDate {
  dateNameHU;
  dateNameRO;
  date;
}

interface IProgramItem {
  locationNameHU;
  locationNameRO;
  programNameHU;
  programNameRO;
  startTime;
  endTime;
  categoryNameHU;
  categoryNameRO;
  currentUserPassType;
  registerPosition;
}

@Options({
  components: {
    QrcodeVue,
  },
})
export default class EventPass extends Vue {
  public dataLoadingInProgress = false;
  public isOfflineUserRoute = false;
  public offlineUser: any = null;
  public size = 300;
  public qrCodeValue = "";
  public userBackupCode = "";
  public dataURL = "";
  public programsList: IProgramDayGroup[] = [];

  public get currentLanguage(): string {
    return this.$i18n.locale?.toUpperCase();
  }

  mounted(): void {
    this.resolveData();
  }

  private async checkResolveOfflineUser(): Promise<void> {
    const routeParams = this.$route?.params;
    const localUser = userService.localUserDetails;
    if (routeParams?.backupCode && localUser.type === "ADMIN") {
      this.isOfflineUserRoute = true;
      try {
        const offlineUser = await offlineUserService.searchOfflineUser(
          routeParams.backupCode
        );
        if (offlineUser?.data?.backupCode) {
          this.offlineUser = offlineUser.data;
        }
      } catch (error) {
        toastService.showToast(
          "Az offline user betoltese kozben valami hiba tortent!",
          "danger"
        );
        this.$router.push("/admin-offline-guests");
      }
    }
  }

  private async generateUserEventsList(): Promise<void> {
    try {
      const response = await userService.getUserEvents(
        this.isOfflineUserRoute ? this.offlineUser.id : null
      );
      if (response?.data) {
        response.data?.days.forEach((date) => {
          const programDate: IProgramDate = {
            dateNameHU: date.dateNameHU,
            dateNameRO: date.dateNameRO,
            date: date.date,
          };
          const programs: IProgramItem[] = [];
          date.locations.forEach((location) => {
            location.programs.forEach((program) => {
              if (program.currentUserPassType) {
                programs.push({
                  locationNameHU: location.locationNameHU,
                  locationNameRO: location.locationNameRO,
                  programNameHU: program.programNameHU,
                  programNameRO: program.programNameRO,
                  startTime: program.startTime,
                  endTime: program.endTime,
                  categoryNameHU: program.categoryNameHU,
                  categoryNameRO: program.categoryNameRO,
                  currentUserPassType: program.currentUserPassType,
                  registerPosition: program.registerPosition,
                });
              }
            });
          });
          if (programs.length > 0) {
            this.programsList.push({
              date: programDate,
              programs: programs,
            });
          }
        });
      }
    } catch (error) {
      toastService.showToast(
        "Az esemeny lista generalasa sikertelen.",
        "danger"
      );
    }
  }

  public async resolveData(): Promise<void> {
    this.dataLoadingInProgress = true;
    await this.checkResolveOfflineUser();
    if (this.isOfflineUserRoute && !this.offlineUser?.id) {
      toastService.showToast(
        "Az offline user betoltese kozben valami hiba tortent!",
        "danger"
      );
      this.$router.push("/admin-offline-guests");
    }
    const user = userService.localUserDetails;
    this.qrCodeValue = this.isOfflineUserRoute
      ? this.offlineUser?.UUID
      : user?.UUID;
    this.userBackupCode = this.isOfflineUserRoute
      ? this.offlineUser?.backupCode
      : user?.backupCode;
    this.dataLoadingInProgress = false;
    JsBarcode("#barcode", this.userBackupCode, { width: 3 });
    this.generateUserEventsList();
  }

  public printPage(): void {
    window.print();
  }

  public goToProgramsList(): void {
    this.$router.push(
      this.isOfflineUserRoute
        ? `/offlineuser/programs/${this.offlineUser.backupCode}`
        : "/programs"
    );
  }
}
</script>

<style lang="scss" scoped>
div.programs-page {
  div.event-pass-wrapper {
    display: flex;
    justify-content: center;
    padding: 4rem 1rem;
  }
  div.qr-code-hash {
    text-align: center;
    color: $basePurple;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  div.actions-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 2rem 1rem;
    button {
      max-width: 350px;
      width: 100%;
    }
  }
  div.program-preview-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    div.programs-list-preview {
      max-width: 750px;
      width: 100%;
      padding: 1rem;
      div.program-date-group {
        margin-bottom: 2rem;
        border-bottom: 2px solid $basePurple;
        &:last-of-type {
          border-bottom: none;
        }
        .group-title {
          color: $basePurple;
          font-weight: 600;
          margin-bottom: 0.3rem;
          font-size: 1.1rem;
        }
        .program-item {
          padding: 0.3rem 0;
          border-bottom: 1px solid #dedede;
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
  .group-title,
  .program-item {
    break-inside: avoid-page;
  }
}
</style>
