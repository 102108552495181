import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';


class ProgramService {
    getPrograms() {
        return axios.get(Constants.API_URL + 'api/eventsextended.json', authHeader());
    }

    saveProgram(program) {
        return axios.post(Constants.API_URL + 'api/event.json', program, authHeader());
    }

    deleteProgram(programId) {
        return axios.delete(Constants.API_URL + `api/event/${programId}.json`, authHeader());
    }

	getConcertList() {
        return axios.get(Constants.API_URL + 'api/concerts.json', authHeader());
    }
}

export default new ProgramService();
