import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import GDPR from "../views/GDPR.vue";
import faq from "../views/faq.vue";
import Login from "../views/Login.vue";
import VerifyAccount from "../views/VerifyAccount.vue";
import AccountCreate from "../views/AccountCreate.vue";
import EventRegistration from "../views/EventRegistration.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import PasswordReset from "../views/PasswordReset.vue";
import ProgramList from "../views/ProgramList.vue";
import EventPass from "../views/EventPass.vue";
import EventLocations from "../views/admin/EventLocations.vue";
import EventDays from "../views/admin/EventDays.vue";
import OfflineGuests from "../views/admin/OfflineGuests.vue";
import ProgramCategories from "../views/admin/ProgramCategories.vue";
import Users from "../views/admin/Users.vue";
import EventPrograms from "../views/admin/EventPrograms.vue";
import EventCheckIn from "../views/admin/CheckIn.vue";
import Constants from "../core/Constants";
import userService from '../services/user.service';
import TopRegistrants from "../views/admin/statistics/TopRegistrants.vue";
import Registrations from "../views/admin/statistics/Registrations.vue";
import Globals from "../views/admin/statistics/Globals.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		component: Home,
		meta: { requiresAuth: false }
	},
	{
		path: "/home",
		component: Home,
		meta: { requiresAuth: false }
	},
	{
		path: "/gdpr",
		component: GDPR,
		meta: { requiresAuth: false }
	},
	{
		path: "/faq",
		component: faq,
		meta: { requiresAuth: true, requiresStatementAccepted: true }
	},
	{
		path: "/login",
		component: Login,
		meta: { requiresAuth: false }
	},
	{
		path: "/verify-account/:userId/:hash",
		component: VerifyAccount,
		meta: { requiresAuth: false }
	},
	{
		path: "/create-account",
		component: AccountCreate,
		meta: { requiresAuth: false }
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		meta: { requiresAuth: false }
	},
	{
		path: "/password-reset/:userId/:hash",
		component: PasswordReset,
		meta: { requiresAuth: false }
	},
	{
		path: "/register",
		component: EventRegistration,
		meta: { requiresAuth: true }
	},
	{
		path: "/programs",
		component: ProgramList,
		meta: { requiresAuth: true, requiresStatementAccepted: true, allowAdmin: true }
	},
	{
		path: "/event-pass",
		component: EventPass,
		meta: { requiresAuth: true, requiresStatementAccepted: true }
	},
	{
		path: "/admin-event-locations",
		component: EventLocations,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/admin-categories",
		component: ProgramCategories,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/admin-programs",
		component: EventPrograms,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/admin-users",
		component: Users,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/admin-offline-guests",
		component: OfflineGuests,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/admin-days",
		component: EventDays,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/statistics/top-registrants",
		component: TopRegistrants,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/statistics/registrations",
		component: Registrations,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/statistics/globals",
		component: Globals,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/admin-event-checkin",
		component: EventCheckIn,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/offlineuser/programs/:backupCode",
		component: ProgramList,
		meta: { requiresAdminAuth: true }
	},
	{
		path: "/offlineuser/event-pass/:backupCode",
		component: EventPass,
		meta: { requiresAdminAuth: true, requiresStatementAccepted: true }
	},
	{
		path: '/:pathMatch(.*)*',
		component: Home,
		meta: { requiresAuth: false }
	}
];

const router = createRouter( {
	history: createWebHistory(),
	routes,
} );

router.beforeEach( ( to, from, next ) =>
{
    const user = localStorage.getItem( Constants.USER_DATA_STORAGE_KEY );
    const userObj = user ? JSON.parse( user ) : null;
    const allowAdmin = to.matched.some( record => record.meta.allowAdmin );
    if( to.matched.some( record => record.meta.requiresAuth ) )
    {
        if( !userObj || ( userObj?.type === "ADMIN" && !allowAdmin ) )
        {
            userService.invalidateUser();
            next( {
                path: '/login'
            } );
        } else
        {
            if( to.matched.some( record => record.meta.requiresStatementAccepted ) && !userObj.statementAccepted )
            {
                next( {
                    path: '/register'
                } );
            }
            else
            {
                next();
            }
        }
    }
    else if( to.matched.some( record => record.meta.requiresAdminAuth ) )
    {
        if( !userObj || userObj?.type !== "ADMIN" )
        {
            userService.invalidateUser();
            next( {
                path: '/login'
            } );
        } else
        {
            //check if isAdmin
            userService.callCheckIsAdmin().then(
                ( response ) =>
                {
                    if( response?.data?.isAdmin !== true )
                    {
                        userService.invalidateUser();
                        next( {
                            path: '/login'
                        } );
                    }
                    else
                    {
                        next();
                    }
                }
            );
        }
    } else
    {
        next();
    }
} )

export default router;
