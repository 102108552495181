
import { Options, Vue } from "vue-class-component";
import statisticsService from "../../../services/statistics.service";

Options({});
export default class Globals extends Vue
{
	async created(): Promise<void>
	{
		await this.LoadData();
	}

	public dataLoadingInProgress = false;
	public dataLoadingFinished = false;
	public dataFetchError = false;
	public globals = null;
	public t1Head = ["Kategória", "Regisztráltak", "Felnött", "Gyerek"];
	public t1Body = ["category", "total", "adults", "kids"];
	public t2Head = ["Felhasználók", "Érvényes fiókok", "Rossz email", "Nem validált fiókok", "Aktiváló mail újraküldve", "Offline", "Gyerekek", "Oltottak", "Teszteltek", "Átestek"];
	public t2Body = ["users", "validusers", "deletedusers", "notconfirmed", "reconfirmationsent", "offline", "kids", "vaccinated", "tested", "recovered"];

	public async LoadData(): Promise<void>
	{
		this.dataLoadingInProgress = true;
		this.dataLoadingFinished = false;
		this.dataFetchError = false;
		this.globals = null;
		try
		{
			const globals = await statisticsService.GetGlobalData ();
			this.globals = globals.data;
			this.dataLoadingFinished = true;
		}
		catch (error)
		{
			this.dataFetchError = true;
		}
		this.dataLoadingInProgress = false;
	}
}
