
import { Options, Vue } from "vue-class-component";
import QrcodeVue from "qrcode.vue";
import userService from "../services/user.service";
import offlineUserService from "../services/offline-user.service";
import toastService from "../services/toast.service";
import JsBarcode from "jsbarcode";

interface IProgramDayGroup {
  date: IProgramDate;
  programs: IProgramItem[];
}

interface IProgramDate {
  dateNameHU;
  dateNameRO;
  date;
}

interface IProgramItem {
  locationNameHU;
  locationNameRO;
  programNameHU;
  programNameRO;
  startTime;
  endTime;
  categoryNameHU;
  categoryNameRO;
  currentUserPassType;
  registerPosition;
}

@Options({
  components: {
    QrcodeVue,
  },
})
export default class EventPass extends Vue {
  public dataLoadingInProgress = false;
  public isOfflineUserRoute = false;
  public offlineUser: any = null;
  public size = 300;
  public qrCodeValue = "";
  public userBackupCode = "";
  public dataURL = "";
  public programsList: IProgramDayGroup[] = [];

  public get currentLanguage(): string {
    return this.$i18n.locale?.toUpperCase();
  }

  mounted(): void {
    this.resolveData();
  }

  private async checkResolveOfflineUser(): Promise<void> {
    const routeParams = this.$route?.params;
    const localUser = userService.localUserDetails;
    if (routeParams?.backupCode && localUser.type === "ADMIN") {
      this.isOfflineUserRoute = true;
      try {
        const offlineUser = await offlineUserService.searchOfflineUser(
          routeParams.backupCode
        );
        if (offlineUser?.data?.backupCode) {
          this.offlineUser = offlineUser.data;
        }
      } catch (error) {
        toastService.showToast(
          "Az offline user betoltese kozben valami hiba tortent!",
          "danger"
        );
        this.$router.push("/admin-offline-guests");
      }
    }
  }

  private async generateUserEventsList(): Promise<void> {
    try {
      const response = await userService.getUserEvents(
        this.isOfflineUserRoute ? this.offlineUser.id : null
      );
      if (response?.data) {
        response.data?.days.forEach((date) => {
          const programDate: IProgramDate = {
            dateNameHU: date.dateNameHU,
            dateNameRO: date.dateNameRO,
            date: date.date,
          };
          const programs: IProgramItem[] = [];
          date.locations.forEach((location) => {
            location.programs.forEach((program) => {
              if (program.currentUserPassType) {
                programs.push({
                  locationNameHU: location.locationNameHU,
                  locationNameRO: location.locationNameRO,
                  programNameHU: program.programNameHU,
                  programNameRO: program.programNameRO,
                  startTime: program.startTime,
                  endTime: program.endTime,
                  categoryNameHU: program.categoryNameHU,
                  categoryNameRO: program.categoryNameRO,
                  currentUserPassType: program.currentUserPassType,
                  registerPosition: program.registerPosition,
                });
              }
            });
          });
          if (programs.length > 0) {
            this.programsList.push({
              date: programDate,
              programs: programs,
            });
          }
        });
      }
    } catch (error) {
      toastService.showToast(
        "Az esemeny lista generalasa sikertelen.",
        "danger"
      );
    }
  }

  public async resolveData(): Promise<void> {
    this.dataLoadingInProgress = true;
    await this.checkResolveOfflineUser();
    if (this.isOfflineUserRoute && !this.offlineUser?.id) {
      toastService.showToast(
        "Az offline user betoltese kozben valami hiba tortent!",
        "danger"
      );
      this.$router.push("/admin-offline-guests");
    }
    const user = userService.localUserDetails;
    this.qrCodeValue = this.isOfflineUserRoute
      ? this.offlineUser?.UUID
      : user?.UUID;
    this.userBackupCode = this.isOfflineUserRoute
      ? this.offlineUser?.backupCode
      : user?.backupCode;
    this.dataLoadingInProgress = false;
    JsBarcode("#barcode", this.userBackupCode, { width: 3 });
    this.generateUserEventsList();
  }

  public printPage(): void {
    window.print();
  }

  public goToProgramsList(): void {
    this.$router.push(
      this.isOfflineUserRoute
        ? `/offlineuser/programs/${this.offlineUser.backupCode}`
        : "/programs"
    );
  }
}
