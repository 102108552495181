import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';


class UserValidationService {
	searchUser(backupCode)
	{
		return axios.get(Constants.API_URL + `api/usersearch/${backupCode}.json`, authHeader());
	}

	validateQR(hash, eventId)
	{
		const data = { hash: hash, event: eventId };
		return axios.post(Constants.API_URL + 'api/validateqr.json', data, authHeader());
	}

	checkin(hash, eventId)
	{
		const data = { hash: hash, event: eventId };
		return axios.post(Constants.API_URL + 'api/concertcheckin.json', data, authHeader());
	}

	checkout(hash, eventId)
	{
		const data = { hash: hash, event: eventId };
		return axios.post(Constants.API_URL + 'api/concertcheckout.json', data, authHeader());
	}
}

export default new UserValidationService();
