
import { Options, Vue } from "vue-class-component";
import toastService from "../../services/toast.service";
import adminUsersService from "../../services/admin-users.service";
import _ from "lodash";

Options({});
export default class Users extends Vue {
  //#region Init
  async created(): Promise<void> {
    await this.fetchAdminUsers();
  }
  //#endregion

  //#region Properties
  public showAdminUserDialog = false;
  public dialogDataSaveInProgress = false;
  public dialogData: any = null;

  public dataLoadingInProgress = false;
  public dataLoadingFinished = false;
  public dataFetchError = false;
  public selectedRows = [];
  public adminUsers: any[] = [];
  public tHead = ["ID", "Name", "Phone", "Email", "Type", { value: "Actions", align: "center" }];
  public tBody = [
    "id",
    "name",
    "phone",
    "email",
    "type",
    {
      slot: "actions",
      align: "center",
    },
  ];
  //#endregion

  //#region Helper methods
  public async fetchAdminUsers(): Promise<void> {
    this.dataLoadingInProgress = true;
    this.dataLoadingFinished = false;
    this.dataFetchError = false;
    this.adminUsers = [];
    try {
      const adminUsers = await adminUsersService.getAdminUsers();
      let users: any[] = [];
      for (const type in adminUsers.data) {
        adminUsers.data[type].forEach((user) => {
          users.push(user);
        });
      }
      this.adminUsers = _.sortBy(users, ["type", "id"]);
      this.dataLoadingFinished = true;
    } catch (error) {
      this.dataFetchError = true;
    }
    this.dataLoadingInProgress = false;
  }
  //#endregion

  //#region List methods
  public editClicked(adminUser): void {
    this.openAdminUserDialog(adminUser);
  }

  public deleteClicked(adminUser): void {
    if (!adminUser?.id) {
      return;
    }
    this.$confirm({
      title: `${adminUser.hu}`,
      message: `Bisztosan ki akarja torolni ezt a felhasznalot?`,
      state: "warning",
      acceptText: "Igen",
      cancelText: "Nem",
    }).then(async (result) => {
      if (result) {
        try {
          await adminUsersService.deleteAdminUser(adminUser?.id);
          toastService.showToast("A felhasznalo torlese sikeres!", "success");
          this.fetchAdminUsers();
        } catch (error) {
          toastService.showToast("A felhasznalo torlese sikertelen!", "danger");
        }
      }
    });
  }
  //#endregion

  //#region Dialog methods
  public openAdminUserDialog(adminUser: any = null): void {
    this.dialogData = {
      id: adminUser?.id || null,
      name: adminUser?.name || null,
      email: adminUser?.email || null,
      phone: adminUser?.phone || null,
      type: adminUser?.type || "SCAN",
    };
    this.showAdminUserDialog = true;
  }

  public async dialogClose(saveClicked: boolean): Promise<void> {
    if (saveClicked) {
      try {
        this.dialogDataSaveInProgress = true;
        await adminUsersService.saveAdminUser(this.dialogData);
      } catch (error) {
        toastService.showToast("A felhasznalo mentese sikertelen!", "danger");
      }
      this.dialogDataSaveInProgress = false;
      this.showAdminUserDialog = false;
      this.dialogData = null;
      await this.fetchAdminUsers();
    } else {
      this.showAdminUserDialog = false;
      this.dialogData = null;
    }
  }
  //#endregion
}
