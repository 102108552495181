<template>
	<div class="admin-list-page">
		<div class="page-header">Regisztrációs sorok</div>
		<div class="admin-page-content">
			<div class="list-wrapper">
				<div class="data-loader" v-if="dataLoadingInProgress">
					<ui-spinner active></ui-spinner>
					<span>Frissítés</span>
				</div>
				<div class="dataHolder" v-if="registrations && !dataLoadingInProgress">
					<div class="dataCell" v-for="registration in registrations" :key="registration.hu">
						<div class="infoWidth">
							<b>{{registration.hu}}</b>
							<br>
							{{registration.day}} ({{registration.startingTime}})
						</div>
						<div class="dataWidth" v-html="formatRegistrations(registration.registrations)"></div>
					</div>
					<div class="dataCell">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import statisticsService from "../../../services/statistics.service";

Options({});
export default class Registrations extends Vue
{
	async created(): Promise<void>
	{
		await this.LoadData();
	}

	public formatRegistrations(registrations: string): string
	{
		let registrantsMap: Map<string, number> = new Map();
		let order: string [] = [];
		if (registrations)
		{
			const splitted = registrations.split('#');
			splitted.forEach(function(registrant) {
				if (registrant)
				{
					if (registrantsMap.has(registrant))
					{
						const count = registrantsMap.get(registrant) ?? 0;
						registrantsMap.set(registrant, count + 1);
					}
					else
					{
						registrantsMap.set(registrant, 1);
						order.push(registrant);
					}
				}
			});
		}

		let result = "";
		let total = 0;
		order.forEach(function(registrant) {
			const count = registrantsMap.get(registrant) ?? 0;
			total = total + count;
			if (count == 1)
			{
				result = result + registrant + ' ';
			}
			else
			{
				result = result + '<span style="color:red;">' + registrant + ' (' + registrantsMap.get(registrant) + ')</span> ';
			}
		});

		return '[' + total + ']: ' + result;
	}

	public dataLoadingInProgress = false;
	public dataLoadingFinished = false;
	public dataFetchError = false;
	public registrations = null;

	public async LoadData(): Promise<void>
	{
		this.dataLoadingInProgress = true;
		this.dataLoadingFinished = false;
		this.dataFetchError = false;
		this.registrations = null;
		try
		{
			const registrations = await statisticsService.GetRegistrations ();
			this.registrations = registrations.data;
			this.dataLoadingFinished = true;
		}
		catch (error)
		{
			this.dataFetchError = true;
		}
		this.dataLoadingInProgress = false;
	}
}
</script>

<style lang="scss">
.dataHolder {
	display: flex;
	flex-wrap: wrap;

	.dataCell {
		background: #e5e5e5;
		display: flex;
		margin: 0 10px 10px 0;
		width: 100%;
		padding: 10px;
		line-height: 20px;

		.infoWidth {
			width: 30%;
		}

		.dataWidth {
			width: 70%;
			word-break: break-word;
		}
	}
}
</style>
