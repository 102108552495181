<template>
	<div class="admin-list-page">
		<div class="page-header">Offline regisztraltak</div>
		<div class="admin-page-content">
			<div class="existing-user-search">
				<ui-textfield outlined v-model="backupCode">Backup code</ui-textfield>
				<ui-button raised :disabled="!backupCode || offlineUserLoadingInProgress" @click="searchAndLoadOfflineUser()">
					<span v-if="!offlineUserLoadingInProgress">Keresés</span>
					<ui-spinner active v-if="offlineUserLoadingInProgress"></ui-spinner>
				</ui-button>
			</div>
			<div class="latest-searched-code" v-if="latestSearchedBackupCodes?.length > 0">
				<span>Legújabb keresett kódok:</span>
				<ui-chips>
					<ui-chip v-for="(backupCode,index) in latestSearchedBackupCodes" :key="index" @click="autofillBackupCode(backupCode)">{{ backupCode }}</ui-chip>
				</ui-chips>
			</div>
			<ui-divider></ui-divider>
			<div class="offline-user-details">
				<div v-if="offlineUser" class="offline-user flex-column">
					<div class="msg">Jelenlegi offline felhasználó:</div>
					<div>Backup Code: {{ offlineUser.backupCode }}</div>
					<div>Létrehozva: {{ formatDate(offlineUser.created) }}</div>
					<div>Módosítva: {{ formatDate(offlineUser.updated) }}</div>
					<div>
						<ui-button unelevated class="danger" @click="changeUser()">Új Felhasználó</ui-button>
					</div>
					<div>
						<ui-button raised @click="goToProgramList()">Program lista</ui-button>
					</div>
					<div>
						<ui-button raised @click="goToEventPass()">Belépő</ui-button>
					</div>
				</div>
				<div class="register-form">
					<ui-form type="1" item-margin-bottom="10">
						<ui-form-field>
							<ui-checkbox v-model="imVaccinated" input-id="imVaccinated"></ui-checkbox>
							<label for="imVaccinated">Oltott vagyok</label>
						</ui-form-field>
						<ui-form-field>
							<ui-checkbox v-model="hadCovid" input-id="hadCovid"></ui-checkbox>
							<label for="hadCovid">Átestem a Covid-on</label>
						</ui-form-field>
						<ui-form-field>
							<ui-checkbox v-model="validPcrTest" input-id="validPcrTest"></ui-checkbox>
							<label for="validPcrTest">Rendelkezni fogok érvényes PCR teszttel</label>
						</ui-form-field>
						<ui-form-field class="text-field required">
							<ui-textfield outlined v-model="name" :attrs="{ name: 'name' }" required>Név</ui-textfield>
						</ui-form-field>
						<ui-form-field class="text-field required">
							<ui-textfield outlined v-model="phone" :attrs="{ name: 'phone' }" required>Telefon</ui-textfield>
						</ui-form-field>
						<ui-form-field class="has-children-switch-field">
							<ui-switch v-model="hasChildren" input-id="hasChildren" v-on:change="hasChildrenSwitchChanged()" :true-value="true" :false-value="false">
								{{ hasChildren }}
							</ui-switch>
							<label for="hasChildren" class="switch-label">Gyermekkel veszek részt a rendezvényen</label>
						</ui-form-field>
						<ui-form-field v-if="hasChildren" class="text-field required">
							<ui-textfield v-model="numberOfChildren" v-on:change="numberOfChildrenChanged()" outlined :attrs="{ name: 'numberOfChildren' }" input-type="number" required>
								Gyerekek száma
							</ui-textfield>
						</ui-form-field>
						<div v-if="hasChildren && numberOfChildren > 0" class="childrens-list">
							<ui-form-field v-for="index in Number(numberOfChildren)" :key="index" class="text-field required">
								<ui-textfield outlined :attrs="{ name: 'numberOfChildren' }" required v-model="childrenList[index - 1]">{{ index }}. Gyerek neve</ui-textfield>
							</ui-form-field>
						</div>
						<ui-alert state="error" v-if="errorMessage !== ''">{{errorMessage}}</ui-alert>
						<ui-button raised class="accept-button" @click="offlineUserSave()" :disabled="statementSaveInProgress || formInvalid">
							<span v-if="offlineUser">Mentés</span>
							<span v-if="!offlineUser">Felhasználó létrehozása</span>
						</ui-button>
					</ui-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import offlineUserService from "../../services/offline-user.service";
import toastService from "../../services/toast.service";
import moment from "moment";

Options({});
export default class OfflineGuests extends Vue
{
	mounted(): void
	{
		this.loadLatestBackupCodes();
	}

	public offlineUserLoadingInProgress = false;
	public offlineUser: any = null;
	public backupCode = null;
	public statementSaveInProgress = false;
	public hasChildren = false;
	public errorMessage = "";
	public imVaccinated = false;
	public hadCovid = false;
	public validPcrTest = false;
	public name = null;
	public phone = null;
	public numberOfChildren = 0;
	public childrenList: string[] = [];
	public latestSearchedBackupCodes: string[] = [];

	public get formInvalid(): boolean
	{
		return (!this.name || this.name === "" || !(this.imVaccinated || this.hadCovid || this.validPcrTest) || !this.phone || this.phone === "");
	}

	public formatDate(date): string
	{
		return moment(date).format("YYYY-MM-DD HH:mm");
	}

	public async searchAndLoadOfflineUser(): Promise<void>
	{
		try
		{
			this.offlineUserLoadingInProgress = true;
			const offlineUser = await offlineUserService.searchOfflineUser(this.backupCode);
			if (offlineUser.data?.id)
			{
				this.offlineUser = offlineUser.data;
				this.name = this.offlineUser?.name;
				this.phone = this.offlineUser?.phone || "";
				this.imVaccinated = this.offlineUser?.vaccinated;
				this.hadCovid = this.offlineUser?.recovered;
				this.validPcrTest = this.offlineUser?.tested;
				this.numberOfChildren = this.offlineUser?.children;
				if (this.numberOfChildren > 0)
				{
					this.hasChildren = true;
					this.numberOfChildrenChanged();
					for (let i = 0; i < this.childrenList.length; i++)
					{
						this.childrenList[i] = this.offlineUser[`child${i + 1}`] || "";
					}
				}
				offlineUserService.storeBackupCode(this.offlineUser.backupCode);
				this.loadLatestBackupCodes();
			}
		}
		catch (error)
		{
			toastService.showToast("Felhasznalo keresese sikertelen", "danger");
			console.warn("Offline user search error ", error);
		}
		this.offlineUserLoadingInProgress = false;
	}

	public changeUser(): void
	{
		this.offlineUser = null;
		this.name = null;
		this.phone = null;
		this.imVaccinated = false;
		this.hadCovid = false;
		this.validPcrTest = false;
		this.numberOfChildren = 0;
		this.childrenList = [];
		this.hasChildren = false;
	}

	public async offlineUserSave(): Promise<void>
	{
		try
		{
			this.errorMessage = "";
			if (this.formInvalid)
			{
				return;
			}
			this.statementSaveInProgress = true;
			const userStatement = await offlineUserService.saveOfflineUser(
				this.offlineUser ? this.offlineUser.id : null,
				this.name,
				this.phone,
				this.imVaccinated,
				this.hadCovid,
				this.validPcrTest,
				this.numberOfChildren,
				this.childrenList
			);
			if (userStatement?.data?.backupCode)
			{
				this.backupCode = userStatement.data.backupCode;
				this.searchAndLoadOfflineUser();
			}
			else
			{
				this.errorMessage = "Valami hiba történt a felhasználólo frissítése közben.";
			}
		}
		catch (error)
		{
			this.errorMessage = "Valami hiba történt a felhasználólo frissítése közben.";
		}
		this.statementSaveInProgress = false;
	}

	public goToProgramList(): void
	{
		this.$router.push(`/offlineuser/programs/${this.offlineUser.backupCode}`);
	}

	public goToEventPass(): void
	{
		this.$router.push(`/offlineuser/event-pass/${this.offlineUser.backupCode}`);
	}

	public autofillBackupCode( backupCode ): void
	{
		this.backupCode = backupCode;
	}

	private loadLatestBackupCodes(): void
	{
		this.latestSearchedBackupCodes = offlineUserService.getLastBackupCode();
	}

	public hasChildrenSwitchChanged(): void
	{
		if (this.hasChildren === false)
		{
			this.numberOfChildren = 0;
			this.childrenList = [];
		}
		else
		{
			this.numberOfChildren = 1;
			this.numberOfChildrenChanged();
		}
	}

	public numberOfChildrenChanged(): void
	{
		if (this.numberOfChildren > 6)
		{
			this.numberOfChildren = 6;
			toastService.showToast("Maximum 6 gyerek hozzáadása lehetséges");
		}
		const currentLength = this.childrenList.length;
		const newLength = this.numberOfChildren;
		if (newLength < currentLength)
		{
			this.childrenList.splice(newLength);
		}
		else
		{
			const newArray: string[] = [];
			for (let i = 0; i < newLength; i++)
			{
				if (this.childrenList[i])
				{
					newArray.push(this.childrenList[i]);
				}
				else
				{
					newArray.push("");
				}
			}
			this.childrenList = newArray;
		}
	}
}
</script>

<style lang="scss" scoped>
div.existing-user-search,
div.latest-searched-code,
div.offline-user-details {
  padding: 1rem;
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  .mdc-form-field,
  .mdc-text-field {
    min-width: 250px;
    max-width: 400px;
    width: 100%;
  }
}

div.existing-user-search {
  padding: 3rem 1rem 1rem 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: row wrap;
  align-items: center;
}

div.offline-user-details {
  div.offline-user {
    border: 2px solid #dedede;
    border-radius: 5px;
    background: #fff9c4;
    padding: 1rem;
    margin-bottom: 2rem;
    > div {
      padding: 5px;
    }
    div.msg {
      font-weight: 600;
      font-size: 1.1rem;
    }
    button {
      height: 40px;
      min-width: 250px;
    }
    button.danger {
      margin-top: 1rem;
      color: white;
      background: #d32f2f;
    }
  }

  div.register-form {
    .text-field {
      margin-top: 1rem;
    }
    .mdc-form-field,
    .mdc-text-field {
      min-width: 350px;
      max-width: 450px;
      width: 100%;
    }
    .has-children-switch-field {
      margin-top: 20px;
      .switch-label {
        margin-left: 10px;
      }
    }
    div.childrens-list {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
