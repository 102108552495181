import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '../core/Constants';
import moment from 'moment';


class OfflineUserService
{
	searchOfflineUser(backupCode)
	{
		return axios.get(Constants.API_URL + `api/usersearch/${backupCode}.json`, authHeader());
	}

	saveOfflineUser(id, name, phone, vaccinated, recovered, tested, childrensNo, childrensName)
	{
		const statementUpdate = {
			id: id,
			statementAccepted: moment().format("YYYY-MM-DD HH:mm:ss"),
			name: name,
			phone: phone,
			vaccinated: vaccinated,
			recovered: recovered,
			tested: tested,
			children: childrensNo
		};
		if (childrensName && childrensName.length > 0)
		{
			childrensName.forEach((childName, i) => {
				statementUpdate[`child${i + 1}`] = childName;
			});
		}
		return axios.post(Constants.API_URL + 'api/user.json', statementUpdate, authHeader());
	}

	getLastBackupCode()
	{
		const backupCodes = localStorage.getItem(Constants.LATEST_SEARCHED_BACKUPCODES_KEY);
		return backupCodes ? JSON.parse(backupCodes) : [];
	}

	storeBackupCode(backupCode)
	{
		const backupCodes = this.getLastBackupCode();
		backupCodes.unshift(backupCode);
		localStorage.setItem(Constants.LATEST_SEARCHED_BACKUPCODES_KEY, JSON.stringify(backupCodes.slice(0, 8)));
	}
}

export default new OfflineUserService();
