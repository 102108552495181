
import { Options, Vue } from "vue-class-component";
import toastService from "../../services/toast.service";
import categoryService from "../../services/category.service";
import _ from "lodash";

Options({});
export default class ProgramCategories extends Vue {
  //#region Init
  async created(): Promise<void> {
    await this.fetchEventCategories();
  }
  //#endregion

  //#region Properties
  public showCategoryDialog = false;
  public dialogDataSaveInProgress = false;
  public dialogData = null;

  public dataLoadingInProgress = false;
  public dataLoadingFinished = false;
  public dataFetchError = false;
  public selectedRows = [];
  public categories = null;
  public tHead = ["ID", "Name HU", "Name RO", { value: "Actions", align: "center" }];
  public tBody = [
    "id",
    "hu",
    "ro",
    {
      slot: "actions",
      align: "center"
    },
  ];
  //#endregion

  //#region Helper methods
  public async fetchEventCategories(): Promise<void> {
    this.dataLoadingInProgress = true;
    this.dataLoadingFinished = false;
    this.dataFetchError = false;
    this.categories = null;
    try {
      const categories = await categoryService.getCategories();
      this.categories = categories.data;
      this.dataLoadingFinished = true;
    } catch (error) {
      this.dataFetchError = true;
    }
    this.dataLoadingInProgress = false;
  }
  //#endregion

  //#region List methods
  public editClicked(category): void {
    this.openCategoryDialog(category);
  }

  public deleteClicked(category): void {
    if (!category?.id) {
      return;
    }
    this.$confirm({
      title: `${category.hu}`,
      message: `Bisztosan ki akarja torolni a kategoriat?`,
      state: "warning",
      acceptText: "Igen",
      cancelText: "Nem",
    }).then(async (result) => {
      if (result) {
        try {
          await categoryService.deleteCategory(category?.id);
          toastService.showToast("A kategoria torlese sikeres!", "success");
          this.fetchEventCategories();
        } catch (error) {
          toastService.showToast("A kategoria torlese sikertelen!", "danger");
        }
      }
    });
  }
  //#endregion

  //#region Dialog methods
  public openCategoryDialog(category = null): void {
    this.dialogData = _.cloneDeep(category) || {
      id: null,
      hu: null,
      ro: null
    };
    this.showCategoryDialog = true;
  }

  public async dialogClose(saveClicked: boolean): Promise<void> {
    if (saveClicked) {
      try {
        this.dialogDataSaveInProgress = true;
        await categoryService.saveCategory(this.dialogData);
      } catch (error) {
        toastService.showToast("A kategoria mentese sikertelen!", "danger");
      }
      this.dialogDataSaveInProgress = false;
      this.showCategoryDialog = false;
      this.dialogData = null;
      await this.fetchEventCategories();
    } else {
      this.showCategoryDialog = false;
      this.dialogData = null;
    }
  }
  //#endregion
}
