
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import offlineUserService from "../services/offline-user.service";
import toastService from "../services/toast.service";
import categoryService from "../services/category.service";
import Constants from "@/core/Constants";
import moment from "moment";

@Options({
  components: {},
})
export default class ProgramList extends Vue {
  public persons = 1;
  public offlineUser: any = null;
  public isOfflineUserRoute = false;
  public userEventsLoadingInProgress = false;
  public programRegisterInProgress = false;
  public activeTab = 0;
  public tabs: { HU: string; RO: string; date: string; locations: any[] }[] =
    [];
  public categories: { HU: string; RO: string; value: number }[] = [];
  public categoryActivityMap: Map<number, boolean> = new Map();
  public selectedFilters: number[] = [];

  public get isAdmin(): boolean {
    return userService?.localUserDetails?.type === "ADMIN";
  }

  public get currentLanguage(): string {
    return this.$i18n.locale?.toUpperCase();
  }

  public get children() {
    if (this.isAdmin) return this.offlineUser?.children;
    else return userService.localUserDetails?.children;
  }

  public get totalPersons() {
    if (this.isAdmin) return this.offlineUser?.children + 1;
    else return userService.localUserDetails?.children + 1;
  }

  mounted(): void {
    this.loadUserEvents();
    this.loadCategories();
  }

  private async checkResolveOfflineUser(): Promise<void> {
    const routeParams = this.$route?.params;
    if (routeParams?.backupCode && this.isAdmin) {
      this.isOfflineUserRoute = true;
      try {
        const offlineUser = await offlineUserService.searchOfflineUser(
          routeParams.backupCode
        );
        if (offlineUser?.data?.backupCode) {
          this.offlineUser = offlineUser.data;
        }
      } catch (error) {
        toastService.showToast(
          this.$t("errorMsgs.offlineUserLoadError"),
          "danger"
        );
        this.$router.push("/admin-offline-guests");
      }
    }
  }

  public async loadCategories(): Promise<void> {
    try {
      const categories = await categoryService.getCategories();
      categories?.data.forEach((category) => {
        this.categories.push({
          HU: category.hu,
          RO: category.ro,
          value: category.id,
        });
        this.categoryActivityMap.set(category.id, true);
        this.selectedFilters.push(this.selectedFilters.length);
      });
    } catch (error) {
      //
    }
  }

  public async loadUserEvents(): Promise<void> {
    try {
      this.offlineUser = null;
      this.isOfflineUserRoute = false;
      this.userEventsLoadingInProgress = true;
      let userId = this.isAdmin ? userService.localUserDetails?.id : null;
      await this.checkResolveOfflineUser();
      if (this.isOfflineUserRoute) {
        userId = this.offlineUser?.id;
        if (!userId) {
          toastService.showToast(
            this.$t("errorMsgs.offlineUserLoadError"),
            "danger"
          );
          this.$router.push("/admin-offline-guests");
        }
      }
      const response = await userService.getUserEvents(userId);
      if (response?.data) {
        response.data?.days.forEach((date) => {
          date.locations.forEach((location) => {
            location.programs.forEach((program) => {
              //We add this property so we can use it for program checkbox true/false tracking
              program["currentUserRegistered"] =
                program.registerPosition > 0 && program.currentUserPassType
                  ? true
                  : false;
              program["participants"] = this.totalPersons;
            });
          });

          this.tabs.push({
            HU: date.dateNameHU,
            RO: date.dateNameRO,
            date: moment(date.date).format("ll"),
            locations: date.locations,
          });
        });
      }
    } catch (error) {
      //
    }
    this.userEventsLoadingInProgress = false;
  }

  public async programCheckChanged(program): Promise<void> {
    this.programRegisterInProgress = true;
    try {
      const userId = this.offlineUser?.id || null;
      if (program?.registerPosition > 0 && program.currentUserPassType) {
        await userService.deregisterUserEvent(program.id, userId);
        toastService.showToast(
          this.$t("programs.deRegisterSuccessMsg", {
            program: program[`programName${this.currentLanguage}`],
          }),
          "info"
        );
        program.currentUserPassType = null;
        program.registerPosition = null;
      } else {
        if (program.participants > this.totalPersons) {
          toastService.showToast(
            this.$t("programs.maxParticipantsOverwritten"),
            "warning"
          );
          program.participants = this.totalPersons; 
        }
        const register = await userService.registerUserEvent(
          program.id,
          userId,
          program.participants,
          this.$i18n.locale
        );
        toastService.showToast(
          this.$t("programs.registerSuccessMsg", {
            program: program[`programName${this.currentLanguage}`],
          }),
          "success"
        );
        program.currentUserPassType = register.data.registrationType;
        program.registerPosition = register.data.registerPosition;
        program.children = program.participants;
      }
    } catch (error) {
      program.currentUserRegistered = !program.currentUserRegistered;
      toastService.showToast(this.$t("programs.programUpdateError"), "danger");
    }
    this.programRegisterInProgress = false;
  }

  public navigateToEventPass(): void {
    this.$router.push(
      this.isOfflineUserRoute
        ? `/offlineuser/event-pass/${this.offlineUser.backupCode}`
        : "/event-pass"
    );
  }

  public getTicketLinkText(link: string): string {
    return this.$t("programs.ticketLinkTxt", { link: link });
  }

  public getRegisteredTotalsText(program): string {
    return this.$t("programs.registeredTxt", {
      participants: program.totalRegistered,
      totalSpots: program.totalPrioritySpots,
      extraSpots: program.totalWaitingListSpots,
    });
  }

  public filteringChanged(category): void {
    const oldValue = this.categoryActivityMap.get(category);
    this.categoryActivityMap.set(category, !oldValue);
  }

  public isActiveFilter(category): boolean {
    return this.categoryActivityMap.get(category) || false;
  }
}
