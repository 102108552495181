
import { Options, Vue } from "vue-class-component";
import offlineUserService from "../../services/offline-user.service";
import toastService from "../../services/toast.service";
import moment from "moment";

Options({});
export default class OfflineGuests extends Vue
{
	mounted(): void
	{
		this.loadLatestBackupCodes();
	}

	public offlineUserLoadingInProgress = false;
	public offlineUser: any = null;
	public backupCode = null;
	public statementSaveInProgress = false;
	public hasChildren = false;
	public errorMessage = "";
	public imVaccinated = false;
	public hadCovid = false;
	public validPcrTest = false;
	public name = null;
	public phone = null;
	public numberOfChildren = 0;
	public childrenList: string[] = [];
	public latestSearchedBackupCodes: string[] = [];

	public get formInvalid(): boolean
	{
		return (!this.name || this.name === "" || !(this.imVaccinated || this.hadCovid || this.validPcrTest) || !this.phone || this.phone === "");
	}

	public formatDate(date): string
	{
		return moment(date).format("YYYY-MM-DD HH:mm");
	}

	public async searchAndLoadOfflineUser(): Promise<void>
	{
		try
		{
			this.offlineUserLoadingInProgress = true;
			const offlineUser = await offlineUserService.searchOfflineUser(this.backupCode);
			if (offlineUser.data?.id)
			{
				this.offlineUser = offlineUser.data;
				this.name = this.offlineUser?.name;
				this.phone = this.offlineUser?.phone || "";
				this.imVaccinated = this.offlineUser?.vaccinated;
				this.hadCovid = this.offlineUser?.recovered;
				this.validPcrTest = this.offlineUser?.tested;
				this.numberOfChildren = this.offlineUser?.children;
				if (this.numberOfChildren > 0)
				{
					this.hasChildren = true;
					this.numberOfChildrenChanged();
					for (let i = 0; i < this.childrenList.length; i++)
					{
						this.childrenList[i] = this.offlineUser[`child${i + 1}`] || "";
					}
				}
				offlineUserService.storeBackupCode(this.offlineUser.backupCode);
				this.loadLatestBackupCodes();
			}
		}
		catch (error)
		{
			toastService.showToast("Felhasznalo keresese sikertelen", "danger");
			console.warn("Offline user search error ", error);
		}
		this.offlineUserLoadingInProgress = false;
	}

	public changeUser(): void
	{
		this.offlineUser = null;
		this.name = null;
		this.phone = null;
		this.imVaccinated = false;
		this.hadCovid = false;
		this.validPcrTest = false;
		this.numberOfChildren = 0;
		this.childrenList = [];
		this.hasChildren = false;
	}

	public async offlineUserSave(): Promise<void>
	{
		try
		{
			this.errorMessage = "";
			if (this.formInvalid)
			{
				return;
			}
			this.statementSaveInProgress = true;
			const userStatement = await offlineUserService.saveOfflineUser(
				this.offlineUser ? this.offlineUser.id : null,
				this.name,
				this.phone,
				this.imVaccinated,
				this.hadCovid,
				this.validPcrTest,
				this.numberOfChildren,
				this.childrenList
			);
			if (userStatement?.data?.backupCode)
			{
				this.backupCode = userStatement.data.backupCode;
				this.searchAndLoadOfflineUser();
			}
			else
			{
				this.errorMessage = "Valami hiba történt a felhasználólo frissítése közben.";
			}
		}
		catch (error)
		{
			this.errorMessage = "Valami hiba történt a felhasználólo frissítése közben.";
		}
		this.statementSaveInProgress = false;
	}

	public goToProgramList(): void
	{
		this.$router.push(`/offlineuser/programs/${this.offlineUser.backupCode}`);
	}

	public goToEventPass(): void
	{
		this.$router.push(`/offlineuser/event-pass/${this.offlineUser.backupCode}`);
	}

	public autofillBackupCode( backupCode ): void
	{
		this.backupCode = backupCode;
	}

	private loadLatestBackupCodes(): void
	{
		this.latestSearchedBackupCodes = offlineUserService.getLastBackupCode();
	}

	public hasChildrenSwitchChanged(): void
	{
		if (this.hasChildren === false)
		{
			this.numberOfChildren = 0;
			this.childrenList = [];
		}
		else
		{
			this.numberOfChildren = 1;
			this.numberOfChildrenChanged();
		}
	}

	public numberOfChildrenChanged(): void
	{
		if (this.numberOfChildren > 6)
		{
			this.numberOfChildren = 6;
			toastService.showToast("Maximum 6 gyerek hozzáadása lehetséges");
		}
		const currentLength = this.childrenList.length;
		const newLength = this.numberOfChildren;
		if (newLength < currentLength)
		{
			this.childrenList.splice(newLength);
		}
		else
		{
			const newArray: string[] = [];
			for (let i = 0; i < newLength; i++)
			{
				if (this.childrenList[i])
				{
					newArray.push(this.childrenList[i]);
				}
				else
				{
					newArray.push("");
				}
			}
			this.childrenList = newArray;
		}
	}
}
